import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  StyledCol,
  StyledH1,
  StyledRow,
  StyledSection,
} from "..";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { useStatus } from "../../hooks";

const Floorplan = () => {
  const {
    listing: { floor_plans },
  } = useListingState();
  const [loading, setLoading] = useState(floor_plans.length > 0);
  useStatus({ name: "Floorplans", loading });

  if (floor_plans.length === 0) return null;
  const options = {
    wrapAround: floor_plans.length > 2,
  };
  return (
    <StyledSection id="floor_plans">
      <StyledRow>
        <StyledCol xs={12}>
          <StyledH1>Floor Plan</StyledH1>
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          <Carousel id="FloorPlans" setLoading={setLoading} options={options}>
            {floor_plans.map((item, i) => (
              <CarouselItem
                key={i}
                url={item.image_urls.original}
                index={i}
                type="floorplan"
              />
            ))}
          </Carousel>
        </StyledCol>
      </StyledRow>
    </StyledSection>
  );
};

export default Floorplan;
