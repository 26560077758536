import { components } from "react-select";

const DropdownIndicator = ({ children, ...props }) => {
  const {
    selectProps: { valid, invalid },
  } = props;
  let Icon = children;
  if (valid) Icon = <Valid />;
  if (invalid) Icon = <Invalid />;
  return (
    <components.DropdownIndicator {...props}>
      {Icon}
    </components.DropdownIndicator>
  );
};

const Valid = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 8 8"
  >
    <path
      fill="#28a745"
      d="M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z"
    />
  </svg>
);

const Invalid = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    fill="none"
    stroke="#e74040"
    viewBox="0 0 12 12"
  >
    <circle cx="6" cy="6" r="4.5" />
    <path strokeLinejoin="round" d="M5.8 3.6h.4L6 6.5z" />
    <circle cx="6" cy="8.2" r=".6" fill="#e74040" stroke="none" />
  </svg>
);

export const selectTheme = {
  theme: (theme) => ({
    ...theme,
    borderRadius: 1,
    colors: {
      ...theme.colors,
      primary: "#ffad44",
      primary50: "#ffc377",
      primary25: "#ffdaaa",
      danger: "#e74040",
      success: "#28a745",
      border: "#748eed",
      neutral10: "#f9f9f9",
      neutral20: "#e5e5e5",
      neutral30: "#d4d4d4",
      neutral50: "#a3a3a3",
      neutral60: "#737373",
      neutral80: "#000",
    },
  }),

  styles: {
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: state.theme.colors.neutral50,
      };
    },
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        color: state.theme.colors.neutral20,
      };
    },
    indicatorSeparator: (provided, state) => ({
      ...provided,
      backgroundColor: state.theme.colors.neutral20,
    }),
    control: (provided, state) => {
      let borderColor = state.theme.colors.neutral20;
      if (state.selectProps.valid) {
        borderColor = state.theme.colors.success;
      } else if (state.selectProps.invalid) {
        borderColor = state.theme.colors.danger;
      } else if (state.isFocused) {
        borderColor = state.theme.colors.border;
      }
      return {
        ...provided,
        boxShadow: "none",
        borderColor,
        backgroundColor: state.theme.colors.neutral10,
        "&:hover": {
          borderColor,
        },
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        zIndex: 5,
      };
    },
  },
  components: { DropdownIndicator },
};
