import { compact } from "lodash";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { useUsers } from "../../adapters/uplist";
import { useAuthentication } from "../../context/AuthenticationContext";
import { useUser } from "../../context/UserContext";
import { isInternal } from "../../helpers";
import { Select } from "../fields";
import { TagLists } from "./TagLists";

const OfficeLabel = ({ name, office_name, team_name }) => (
  <div>
    {name}
    {team_name && ` : ${team_name}`}
    {office_name && <small className="text-muted"> : {office_name}</small>}
  </div>
);

const userOption = (user) => {
  if (!user) return null;
  const { id, name, office_name, team_name } = user;
  const label = compact([name, office_name, team_name]).join(" ");
  return {
    label,
    value: id,
  };
};

export const InternalAgentSelect = () => {
  const { pathname } = useLocation();
  const isSubmitPage = pathname === "/submit";
  const history = useHistory();
  const { user } = useUser();
  const { data: users = [], isInitialLoading } = useUsers();
  const { setRedirectPath } = useAuthentication();

  const findUser = (id) => users?.find((u) => u.id === id);

  const handleSignup = () => {
    setRedirectPath(pathname);
    history.push("/account-info");
  };

  if (!isInternal()) return null;

  return (
    <>
      <Row className="mb-4">
        <Col xs={12}>
          <Form.Label className="text-muted">Agent</Form.Label>
        </Col>
        <Col>
          <Select
            sku="user_id"
            groupClassName="mb-0"
            options={users?.map(userOption)}
            fieldProps={{ name: "user_id" }}
            placeholder="Search..."
            isLoading={isInitialLoading}
            formatOptionLabel={({ value }) => (
              <OfficeLabel {...findUser(value)} />
            )}
            label={false}
            isClearable
          />
        </Col>
        {!user && (
          <>
            <Col
              xs={1}
              className="d-flex align-items-center justify-content-center"
            >
              <div>or</div>
            </Col>
            <Col xs={5}>
              <Button className="w-100" onClick={handleSignup}>
                Create Agent
              </Button>
            </Col>
          </>
        )}
      </Row>
      {!!user && (
        <>
          {user.suspended && (
            <Alert variant="danger">
              <Alert.Heading>This Agent Is Suspended</Alert.Heading>
              <div>
                Please confirm with management before creating an order.
              </div>
            </Alert>
          )}
          {!isSubmitPage && <TagLists alert_types={["booking", "new"]} />}
        </>
      )}
    </>
  );
};
