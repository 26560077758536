import { each, isBoolean, isFinite, map } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Container, Modal } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useDebounce } from "use-debounce";
import { useBundles } from "../adapters/uplist";
import { useInSqftRange, useModal } from "../hooks";

const BundleContext = createContext();

const useAllProductsSelected = (products = []) => {
  const skus = map(products, "sku");
  useWatch(skus); //triggers re-evaluating if its selected.
  const { getValues } = useFormContext();
  const selected = products.filter(({ sku, quantity }) => {
    const value = getValues(sku);
    return (
      (isBoolean(value) && value && quantity === 1) ||
      (isFinite(value) && value >= quantity)
    );
  });
  return useDebounce(selected.length === skus.length, 100)[0];
};

const Item = ({ handleClose }) => {
  const { setValue } = useFormContext();
  const { selectedBundle, setSelectedBundle, onSelect } = useBundleContext();

  const onRemove = () => {
    setValue(selectedBundle, false);
    setSelectedBundle(null);
    handleClose();
  };
  const onCancel = () => {
    onSelect(selectedBundle, true);
    handleClose();
  };
  return (
    <>
      <Modal.Body className="p-5">
        <Container>
          <h2>
            <strong>This product is part of your bundle.</strong>
          </h2>
          <h2>
            <strong>Removing it will remove your bundle price.</strong>
          </h2>
        </Container>
      </Modal.Body>
      <Modal.Footer className="p-5" style={{ justifyContent: "stretch" }}>
        <Button
          variant="outline-danger"
          size="lg"
          className="flex-grow-1"
          onClick={onRemove}
        >
          Remove
        </Button>
        <Button size="lg" className="flex-grow-1" onClick={onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </>
  );
};

export const BundleProvider = (props) => {
  const { data: bundles = [] } = useBundles();
  const [selectedBundle, setSelectedBundle] = useState(null);
  const { setValue, unregister } = useFormContext();
  const getProducts = useCallback(
    (sku) => bundles.find((b) => sku === b.sku)?.products,
    [bundles]
  );
  const all_selected = useAllProductsSelected(getProducts(selectedBundle));
  const inSqftRange = useInSqftRange();
  const { handleModal } = useModal();

  useEffect(() => {
    if (!all_selected) {
      handleModal(<Item />, {
        centered: true,
        backdrop: "static",
      });
    }
  }, [all_selected, handleModal]);

  const onSelect = useCallback(
    (sku, enabled) => {
      const previous_sku = selectedBundle;
      if (previous_sku && (previous_sku !== sku || !enabled)) {
        const previous_skus = getProducts(previous_sku);
        each(previous_skus, ({ sku }) => {
          unregister(sku);
        });
      }
      if (enabled) {
        const selected_skus = getProducts(sku);
        each(selected_skus, ({ quantity, sku }) => {
          if (sku === "1301") {
            setValue("1301", quantity);
          } else {
            setValue(sku, true);
          }
        });
      }
      setSelectedBundle(enabled ? sku : null);
    },
    [getProducts, selectedBundle, setValue, unregister]
  );

  useEffect(() => {
    if (selectedBundle) {
      const bundle = bundles.find((b) => b.sku === selectedBundle);
      const isInRange = inSqftRange(bundle.sqft_range);
      if (!isInRange) {
        const new_bundle = bundles.find(
          (b) => b.name === bundle.name && inSqftRange(b.sqft_range)
        );
        if (new_bundle) {
          setValue(bundle.sku, false);
          setValue(new_bundle.sku, true);
          onSelect(new_bundle.sku, true);
        }
      }
    }
  }, [selectedBundle, inSqftRange, bundles, onSelect, setValue]);

  return (
    <BundleContext.Provider
      value={{
        bundles,
        selectedBundle,
        setSelectedBundle,
        onSelect,
      }}
      {...props}
    />
  );
};

export const useBundleContext = () => useContext(BundleContext);
