import { useWatch } from "react-hook-form";
import { useBundleContext } from "../context/BundlesContext";
import { useProduct } from "../adapters/uplist";
import { toCurrency } from "../helpers";

export const useProductDiscount = ({
  other_product_skus,
  product_sku,
  discount_sku,
  noBundle = true,
}) => {
  const { selectedBundle } = useBundleContext();
  const otherProducts = useWatch({ name: other_product_skus });
  const { data: product } = useProduct(product_sku);
  const { data: discount } = useProduct(discount_sku);
  return (
    (!noBundle || !selectedBundle) &&
    otherProducts.some(Boolean) &&
    toCurrency(product.price + discount.price)
  );
};
