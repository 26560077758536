import createDataContext from "./createDataContext";

const statusReducer = (state, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload.id]: {
            isLoading: action.payload.loading,
          },
        },
      };
    case "SET_ERROR":
      return {
        ...state,
        error: `${action.payload.error} - ${action.payload.id}`,
      };
    case "SET_LISTING":
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};

const setLoading = (dispatch) => (id, loading) => {
  dispatch({ type: "SET_LOADING", payload: { id, loading } });
};
const setError = (dispatch) => (id, error) => {
  dispatch({ type: "SET_ERROR", payload: { id, error } });
};
const setStatusListing = (dispatch) => (listing) => {
  dispatch({ type: "SET_LISTING", payload: listing });
};

export const {
  Provider,
  Context,
  ActionsContext,
  useStateContext,
  useActionsContext,
} = createDataContext(
  statusReducer,
  { setLoading, setError, setStatusListing },
  {}
);
