import React, { forwardRef } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useModal } from "../../hooks";
import CarouselModal from "./CarouselModal";

const Icon = forwardRef(({ modal, ...props }, ref) => {
  const { handleModal } = useModal();
  return (
    <div
      ref={ref}
      className="info-icon"
      onClick={(e) => {
        e.preventDefault();
        modal &&
          handleModal(modal, {
            dialogClassName: "sample-modal",
            centered: true,
          });
      }}
      {...props}
    >
      <div>?</div>
    </div>
  );
});

const InfoIcon = ({ modal }) => {
  return (
    <OverlayTrigger overlay={<Tooltip>See Samples</Tooltip>} placement="auto">
      <Icon modal={<CarouselModal {...modal} />} />
    </OverlayTrigger>
  );
};

export default InfoIcon;
