import AFurnished from "./A-Furnished.png";
import AStandard from "./A-Standard.png";
import BFurnished from "./B-Furnished.png";
import BStandard from "./B-Standard.png";
import CFurnished from "./C-Furnished.png";
import CStandard from "./C-Standard.png";
import DFurnished from "./D-Furnished.png";
import DStandard from "./D-Standard.png";
import EFurnished from "./E-Furnished.png";
import EStandard from "./E-Standard.png";
import BlankFurnished from "./Blank-Furnished.png";
import BlankStandard from "./Blank-Standard.png";

const STANDARD_FP_ADDON_IMAGES = {
  A: {
    standard: AStandard,
    furnished: AFurnished,
  },
  B: {
    standard: BStandard,
    furnished: BFurnished,
  },
  C: {
    standard: CStandard,
    furnished: CFurnished,
  },
  D: {
    standard: DStandard,
    furnished: DFurnished,
  },
  E: {
    standard: EStandard,
    furnished: EFurnished,
  },
  none: {
    standard: BlankStandard,
    furnished: BlankFurnished,
  },
};

export default STANDARD_FP_ADDON_IMAGES;
