export const presents = (listing) => {
  let text = "Present";
  if (
    (listing.team !== null && !listing.team.pluralize) ||
    (listing.team === null && listing.agents.length === 1)
  ) {
    text += "s";
  } else if (listing.agents.length === 0) {
    text = "Presenting";
  }
  return text;
};
