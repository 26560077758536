import { createContext, useContext, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useModal, useProductInput } from "../hooks";
import ZoneChargeModal from "../components/order/ZoneChargeModal";
import { useTravelZones } from "../adapters/uplist";
import { Loading } from "../components/order/Loading";

const TravelZoneContext = createContext();

export const TravelZoneProvider = ({ children }) => {
  const [zoneWarned, setZoneWarned] = useState(false);
  const { handleModal } = useModal();
  const { setValue } = useFormContext();
  const { data: travelZones, isInitialLoading } = useTravelZones();
  const { fieldProps } = useProductInput({ sku: "address_info.zone_sku" });
  const zone_sku = fieldProps.value;
  const setTravelZone = async (location) => {
    const maps = window.google.maps;
    let zone_sku = "out-of-area";
    for (let zone of travelZones) {
      const { coordinates } = zone;
      const polygon = new maps.Polygon({ path: coordinates });
      if (maps.geometry.poly.containsLocation(location, polygon)) {
        zone_sku = zone.sku === "none" ? false : zone.sku;
        break;
      }
    }
    setValue("address_info.zone_sku", zone_sku);
    return zone_sku;
  };

  useEffect(() => {
    setZoneWarned(false);
  }, [zone_sku]);

  useEffect(() => {
    if (zone_sku && !zoneWarned) {
      handleModal(<ZoneChargeModal sku={zone_sku} />, {
        size: "lg",
        centered: true,
        id: "OrderModal",
      });
      setZoneWarned(true);
    }
  }, [zone_sku, handleModal, zoneWarned]);

  return (
    <TravelZoneContext.Provider value={{ travelZones, setTravelZone }}>
      {isInitialLoading ? <Loading /> : children}
    </TravelZoneContext.Provider>
  );
};

export const useTravelZone = () => useContext(TravelZoneContext);
