import React from "react";
import styled from "styled-components";
import { StyledCol, StyledContainer, StyledRow } from "../common/Wrappers";
import useWindowSize from "../../hooks/useWindowSize";

const InfoWrapper = styled(StyledRow)`
  color: ${(p) => p.theme.text.main};
  font-family: ${(p) => p.theme.fonts.visby.regular};
  padding: 10px 0;
`;

const Item = styled(StyledCol)`
  &&& {
    border-style: solid;
    border-color: ${(p) => p.theme.background.light_border};
    font-size: ${(p) => p.theme.fonts.size.text[p.size]};
    border-width: 0 1px 0 0;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-of-type {
      padding-left: 0;
      border-right: 0;
    }
    &:last-of-type {
      padding-right: 0;
      border-right: 0;
    }
  }
`;

const useInfoItems = (listing) => {
  function getPrice() {
    return listing.price !== null && listing.show_price
      ? `$${listing.price.toLocaleString()}`
      : null;
  }

  function getArea() {
    return listing.finished_area !== null
      ? `${listing.finished_area} ft²`
      : null;
  }
  function getBeds() {
    return listing.beds !== null ? `${listing.beds} Bed` : null;
  }

  function getBaths() {
    return listing.baths !== null || listing.baths_dec !== null
      ? `${listing.baths_dec > 0 ? listing.baths_dec : listing.baths} Bath`
      : null;
  }

  return [getPrice(), getBeds(), getBaths(), getArea()];
};

const MobileSummary = ({ listing }) => {
  const { isNotSmall } = useWindowSize();
  const fontSize = isNotSmall ? ["xl", "lg"] : ["md", "sm"];
  const info_items = useInfoItems(listing);
  if (info_items.every((item) => item === null)) return null;
  return (
    <StyledContainer>
      <InfoWrapper>
        {info_items.map((item, i) => {
          if (item === null) return null;
          let size = i === 0 ? fontSize[0] : fontSize[1];
          return (
            <Item key={i} size={size}>
              {item}
            </Item>
          );
        })}
      </InfoWrapper>
    </StyledContainer>
  );
};

export default MobileSummary;
