import React, { useContext } from "react";
import { Nav, Navbar } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  TitleBar,
  NavLink,
  SmNavLink,
  Infosheet,
  Share,
  ContactAgentModal,
} from "..";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { ThemeContext } from "styled-components";
import { useModal, useWindowSize } from "../../hooks";
import { PDFDownloadLink } from "@react-pdf/renderer";

const StyledNavbar = styled(Navbar)`
  background-color: ${(p) => p.theme.background.dark};
  color: ${(p) => p.theme.primary.text};
  font-family: ${(p) => p.theme.fonts.visby.bold};
  font-size: ${(p) => p.theme.fonts.size.text[p.theme.isXLarge ? "sm" : "md"]};
  line-height: ${(p) => (p.theme.isXLarge ? 24 : 32)}px;
  letter-spacing: 0.44px;
  padding: ${(p) => `${p.theme.spacing.standard} ${p.theme.spacing.wide}`};
`;

const NavbarToggle = styled(Navbar.Toggle)`
  &&& {
    color: ${(p) => p.theme.primary.text};
    border: none;
    font-size: 30px;
  }
`;

const getLinks = (listing) => {
  const default_links = [
    { id: "Photos", text: "GALLERY" },
    { id: "details", text: "DETAILS" },
    { id: "open_houses", text: "VIRTUAL OPEN HOUSE" },
    { id: "floor_plans", text: "FLOOR PLAN" },
    { id: "videos", text: "VIDEOS" },
    { id: "virtual_tours", text: "360° TOUR" },
    { id: "agents", text: "AGENTS" },
  ];
  return default_links.filter(
    (item) => listing[item.id.toLowerCase()]?.length > 0
  );
};

const ListingNavbar = () => {
  const { isLarge } = useWindowSize();
  const { listing } = useListingState();
  const theme = useContext(ThemeContext);
  const links = getLinks(listing);
  const { handleModal } = useModal();
  function handlePDFDownload({ target }) {
    target.innerText = "INFOSHEET DOWNLOADED";
    setTimeout(() => {
      target.innerText = "DOWNLOAD INFOSHEET";
    }, 5000);
  }
  return (
    <StyledNavbar expand="xl" $isLg={isLarge}>
      <TitleBar className="d-lg-none" light={false} />
      <NavbarToggle className="ml-auto">
        <FontAwesomeIcon icon={["fal", "bars"]} />
      </NavbarToggle>
      <Navbar.Collapse>
        <Nav className="mr-auto">
          {links.map((link) => (
            <NavLink key={link.id} href={`#${link.id}`}>
              {link.text}
            </NavLink>
          ))}
        </Nav>
        <Nav>
          {listing.agents.length > 0 && (
            <SmNavLink onClick={() => handleModal(<ContactAgentModal />)}>
              CONTACT AGENTS
            </SmNavLink>
          )}
          {listing.mls && (
            <SmNavLink
              className="nav-link"
              as={PDFDownloadLink}
              document={<Infosheet theme={theme} listing={listing} />}
              fileName={`${listing.address} infosheet.pdf`}
            >
              {({ loading }) => (
                <div onClick={handlePDFDownload}>
                  {loading ? "LOADING INFOSHEET" : "DOWNLOAD INFOSHEET"}
                </div>
              )}
            </SmNavLink>
          )}
          <SmNavLink onClick={() => handleModal(Share)}>SHARE +</SmNavLink>
        </Nav>
      </Navbar.Collapse>
    </StyledNavbar>
  );
};

export default ListingNavbar;
