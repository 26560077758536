import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
}

const useWindowSize = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const isXLarge = useMediaQuery({ minWidth: 1200 });
  const isLarge = useMediaQuery({ minWidth: 992 });
  const isMedium = useMediaQuery({ minWidth: 768 });
  const isNotSmall = useMediaQuery({ minWidth: 576 });
  const isPortrait = useMediaQuery({ orientation: "portrait" });
  return {
    isXLarge,
    isLarge,
    isMedium,
    isNotSmall,
    isPortrait,
    windowDimensions,
  };
};

export default useWindowSize;
