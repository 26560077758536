import styled from "styled-components";
import { useMaxHeight, useWindowSize } from "../../hooks";

const Item = styled.div`
  margin-right: ${(p) => (p.theme.isNotSmall ? "30px" : "15px")};
  background-color: ${(p) => p.theme.primary.main};
`;

const Image = styled.img`
  max-height: ${(p) => p.maxHeight}px;
`;
const CarouselItem = ({ url, index, type }) => {
  const { isPortrait } = useWindowSize();
  const maxHeight = useMaxHeight([200, 600]);
  return (
    <Item className={`flickity-carousel-item ${type}`}>
      <Image
        className={isPortrait ? "portrait" : "landscape"}
        maxHeight={maxHeight}
        alt={`Slide ${index}`}
        data-flickity-lazyload={url}
      />
    </Item>
  );
};

export default CarouselItem;
