import One from "./1.jpg";
import Two from "./2.jpg";
import Three from "./3.jpg";
import Four from "./4.jpg";
import Five from "./5.jpg";
import Six from "./6.jpg";
import Seven from "./7.jpg";
import Eight from "./8.jpg";
import Nine from "./9.jpg";
import Ten from "./10.jpg";
import Eleven from "./11.jpg";
import Twelve from "./12.jpg";
import Thirteen from "./13.jpg";
const Photography = [
  One,
  Two,
  Three,
  Four,
  Five,
  Six,
  Seven,
  Eight,
  Nine,
  Ten,
  Eleven,
  Twelve,
  Thirteen,
];
export default Photography;
