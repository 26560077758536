import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import FlickityCarousel from "./FlickityCarousel";

const CarouselModal = ({ handleClose, entered, ...props }) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timer;
    if (entered) {
      timer = setTimeout(() => setShow(true), 300);
    } else {
      setShow(false);
    }
    return () => clearTimeout(timer);
  }, [entered]);
  return (
    <>
      <Modal.Body>
        {show && (
          <div className="close-icon" onClick={handleClose}>
            <Icon icon={["fal", "times"]} />
          </div>
        )}
        <FlickityCarousel id="modalCarousel" alt_name="Sample" {...props} />
      </Modal.Body>
    </>
  );
};

export default CarouselModal;
