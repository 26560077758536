import React, { useEffect, useRef, useState } from "react";
import reactDom from "react-dom";
import styled from "styled-components";
import Flickity from "flickity";
import { usePrevious, useWindowSize } from "../../hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStateContext as useStatusState } from "../../context/StatusContext";
import { some } from "lodash";

const NavButton = styled.button`
  background-color: ${(p) => p.theme.primary.main};
  color: ${(p) => p.theme.primary.text};
  &:hover {
    background-color: ${(p) => p.theme.primary.hover};
  }
`;

const FlickityWrapper = styled.div`
  margin-bottom: ${(p) => p.theme.spacing.wide};
`;

const Expand = styled(NavButton)`
  border-radius: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${(p) => p.theme.fonts.size.text.md};
`;

const ExpandButton = ({ handleNav }) => (
  <Expand
    className="flickity-button flickity-fullscreen-button flickity-fullscreen-button-view"
    onClick={() => handleNav("expand")}
  >
    <FontAwesomeIcon icon={["fal", "expand"]} />
  </Expand>
);

const NavButtons = ({ handleNav }) => {
  const buttons = [
    ["previous", "left"],
    ["next", "right"],
  ];
  return (
    <>
      {buttons.map((button) => (
        <NavButton
          key={button[0]}
          className={`flickity-button flickity-prev-next-button ${button[0]}`}
          onClick={() => handleNav(button[0])}
        >
          <FontAwesomeIcon icon={["fal", `arrow-${button[1]}`]} />
        </NavButton>
      ))}{" "}
    </>
  );
};

const FlickityContainer = ({ id, fullscreen, fwdRef, handleNav, count }) => {
  const { isNotSmall } = useWindowSize();
  return (
    <FlickityWrapper
      id={id}
      className={`carousel-wrapper ${fullscreen ? "full-screen" : ""}`}
    >
      <div ref={(node) => (fwdRef.current = node)} />
      {isNotSmall && count > 1 && <NavButtons handleNav={handleNav} />}
      <ExpandButton handleNav={handleNav} />
    </FlickityWrapper>
  );
};

const Carousel = ({ id, children, setLoading, options }) => {
  const [ready, setReady] = useState(false);
  const wasReady = usePrevious(ready);
  const prevChildren = usePrevious(children.length);
  const [flickity, setFlickity] = useState();
  const flickityRef = useRef();
  const [fullscreen, setFullscreen] = useState(false);
  const [loadCount, setLoadCount] = useState(0);
  const { loading } = useStatusState();
  let isLoading = loading === undefined || some(loading, { isLoading: true });
  const lazyLoad = 2;
  const lazyLoaded = loadCount > lazyLoad * 2 || loadCount >= children.length;
  useEffect(() => {
    const flickityDidBecomeActive = !wasReady && ready;
    const childrenDidChange = prevChildren !== children.length;
    if (ready && (flickityDidBecomeActive || childrenDidChange))
      refreshFlickity();
  });

  useEffect(() => {
    if (flickity) {
      flickity.on("fullscreenChange", (bool) => {
        flickity.focus();
        setFullscreen(bool);
      });
      flickity.on("staticClick", (event, pointer, cellElement, cellIndex) => {
        if (!(cellIndex >= 0)) {
          flickity.exitFullscreen();
        }
      });
      flickity.on("lazyLoad", (e, cell) => {
        setLoadCount((count) => count + 1);
      });
    }
  }, [flickity]);

  useEffect(() => {
    if (lazyLoaded) setLoading(false);
  }, [lazyLoaded, setLoading]);

  useEffect(() => {
    if (flickity && !isLoading) {
      flickity.options.lazyLoad = 15;
      flickity.lazyLoad();
    }
  }, [isLoading, flickity]);

  useEffect(() => {
    if (flickityRef.current) {
      const flickityOptions = {
        pageDots: false,
        prevNextButtons: false,
        cellSelector: ".flickity-carousel-item",
        wrapAround: true,
        lazyLoad,
        ...options,
      };
      setFlickity(new Flickity(flickityRef.current, flickityOptions));
      setReady(true);
    }
  }, [flickityRef, options]);

  function refreshFlickity() {
    flickity.reloadCells();
    flickity.resize();
    flickity.updateDraggable();
  }

  function renderPortal() {
    if (!flickityRef.current) return null;
    const mountNode = flickityRef.current.querySelector(".flickity-slider");
    if (mountNode) return reactDom.createPortal(children, mountNode);
  }

  function handleNav(direction) {
    switch (direction) {
      case "previous":
        return flickity.previous();
      case "next":
        return flickity.next();
      case "expand":
        return flickity.toggleFullscreen();
      default:
    }
  }

  return [
    <FlickityContainer
      fwdRef={flickityRef}
      count={children.length}
      handleNav={handleNav}
      fullscreen={fullscreen}
      key="flickityContainer"
      id={id}
    />,
    renderPortal(),
  ].filter(Boolean);
};

export default Carousel;
