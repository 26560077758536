import { useCallback } from "react";
import { useModalContext } from "../context/ModalContext";

export const useModal = () => {
  const { setVisible, setContent, setOptions } = useModalContext();
  const handleModal = useCallback(
    (content = false, options = {}) => {
      // setVisible((v) => !v);
      setVisible(true);
      if (content) {
        setContent(content);
        setOptions(options);
      }
    },
    [setContent, setOptions, setVisible]
  );
  return { handleModal };
};

export default useModal;
