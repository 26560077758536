import { Nav } from "react-bootstrap";
import styled from "styled-components";
import { StyledCol } from "..";

export const NavLink = styled(Nav.Link)`
  &&& {
    color: ${(p) => p.theme.primary.text};
    padding-top: 0;
    padding-bottom: 0;
    &:hover,
    &:active,
    &:focus,
    &.active {
      color: ${(p) => p.theme.primary.text};
      text-decoration: underline;
    }
    &:first-of-type {
      padding-left: 0;
      margin-top: 10px;
      @media (min-width: 1200px) {
        margin-top: 0;
      }
    }
    &:last-of-type {
      padding-right: 0;
    }
  }
`;

export const SmNavLink = styled(NavLink)`
  &&& {
    border-style: solid;
    border-width: 0 ${(p) => (p.theme.isXLarge ? 1 : 0)}px 0 0;
    border-color: ${(p) => p.theme.primary_light.press};
    font-size: ${(p) =>
      p.theme.fonts.size.text[p.theme.isXLarge ? "xs" : "md"]};
    letter-spacing: 1.75px;
    &:last-of-type {
      border-width: 0;
    }
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export const LinkBar = styled(StyledCol)`
  text-align: center;
  margin-bottom: ${(p) => p.theme.spacing.wide};
`;

export const Link = styled(Nav.Link)`
  display: inline-block;
  color: ${(p) => p.theme.text.light};
  text-transform: uppercase;
  font-family: ${(p) => p.theme.fonts.visby.bold}
  &.active,
  &:hover {
    color: ${(p) => p.theme.text.main};
    text-decoration: underline;
  }
  &.active {
    cursor: default;
  }
`;
