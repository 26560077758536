import { useState } from "react";
import { createContext, useContext } from "react";

const ModalContext = createContext();

export const ModalProvider = (props) => {
  const [options, setOptions] = useState({});
  const [visible, setVisible] = useState(false);
  const [content, setContent] = useState(null);
  return (
    <ModalContext.Provider
      value={{ options, setOptions, visible, setVisible, content, setContent }}
      {...props}
    />
  );
};

export const useModalContext = () => useContext(ModalContext);
