import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import styled from "styled-components";
import uplist from "../../adapters/uplist";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { useModalContext } from "../../context/ModalContext";

const SubmitButton = styled(Button)`
  &&& {
    background-color: ${(p) => p.theme.primary.main};
    &:active,
    &:focus,
    &:hover {
      background-color: ${(p) => p.theme.primary.hover};
    }
  }
`;

const ContactAgentModal = () => {
  const { listing } = useListingState();
  const [form_data, setFormData] = useState({ listing_id: listing.id });
  const [validated, setValidated] = useState(false);
  const [sending, setSending] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { setVisible } = useModalContext();
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setSending(true);
    if (form.checkValidity() === true) {
      await uplist.post(`/listing/contact_agents`, { form_data }).then(() => {
        setValidated(false);
        setSubmitted(true);
        setTimeout(() => setVisible(false), 3000);
        // form.reset();
      });
    }
    // form.reset();
    setSending(false);
    setValidated(true);
  };
  const handleChange = ({ target: { name, value } }) => {
    setFormData((data) => {
      return { ...data, [name]: value };
    });
  };

  return (
    <div>
      <Modal.Header closeButton>
        <Modal.Title>{`Contact ${listing.agent_names}`}</Modal.Title>
      </Modal.Header>
      {submitted ? (
        <Modal.Body>Sent!</Modal.Body>
      ) : (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Name*</Form.Label>
              <Form.Control
                name="name"
                placeholder="Your name"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide your name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Email*</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="example@email.ca"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control name="phone" type="tel" onChange={handleChange} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Message*</Form.Label>
              <Form.Control
                name="message"
                as="textarea"
                rows="5"
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">{`Please write a message to send to ${listing.agent_names}.`}</Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <SubmitButton disabled={sending} type="submit">
              {sending ? "Submitting" : "Submit"}
            </SubmitButton>
          </Modal.Footer>
        </Form>
      )}
    </div>
  );
};

export default ContactAgentModal;
