import createDataContext from "./createDataContext";

const listingReducer = (state, action) => {
  switch (action.type) {
    case "SET_LISTING":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

const setListing = (dispatch) => (listing) => {
  dispatch({ type: "SET_LISTING", payload: listing });
};

export const {
  Provider,
  Context,
  ActionsContext,
  useStateContext,
  useActionsContext,
} = createDataContext(listingReducer, { setListing }, {});
