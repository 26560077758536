/*global google*/
import React from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useStateContext as useListingState } from "../../context/ListingContext";
import home from "../../assets/icons/home.svg";
const Map = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    mapIds: ["914bfa4cbc2febf9"],
  });
  const { listing } = useListingState();
  const position = {
    lat: parseFloat(listing.latitude),
    lng: parseFloat(listing.longitude),
  };
  if (loadError) console.log(loadError);
  if (loadError) return <div>Map cannot be loaded right now, sorry.</div>;
  if (!isLoaded) return <p>Loading...</p>;

  return (
    <GoogleMap
      mapContainerStyle={{
        minHeight: "600px",
        height: "100%",
        width: "100%",
      }}
      options={{
        // mapId: "21c3ab324f0d2a49",
        disableDefaultUI: true,
      }}
      zoom={15}
      center={position}
    >
      <Marker
        position={position}
        icon={{
          url: home,
          anchor: new google.maps.Point(22.5, 17.5),
        }}
        clickable={false}
      />
    </GoogleMap>
  );
};
export default Map;
