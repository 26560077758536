import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useProduct } from "../../adapters/uplist";
import { isInternal } from "../../helpers";

const ChargeText = ({ product }) => {
  const price_text =
    product.price === 0 ? "" : `of $${product?.price} per technician`;
  return (
    <>
      It looks like this property is outside of our normal service area. Because
      of this, a travel charge {price_text} may apply. We will contact you after
      you place your order with a travel quote.
    </>
  );
};

const NotAvailable = () => {
  if (isInternal())
    return "It looks like this property is outside of our available service area. Please confirm with managment before completing this order.";
  return (
    <>
      It looks like this property is outside of our available service area. If
      you think this is incorrect please contact us by phone or email.
      Otherwise, please enter a different address.
    </>
  );
};

const ZoneChargeModal = ({ handleClose, sku }) => {
  const { data: product } = useProduct(sku);

  return (
    <>
      <Modal.Body className="p-5">
        <Container>
          <h2>
            <strong>
              {sku === "out-of-area" ? (
                <NotAvailable />
              ) : (
                <ChargeText product={product} />
              )}
            </strong>
          </h2>
        </Container>
      </Modal.Body>
      <Modal.Footer className="p-5 justify-content-start">
        <Row className="flex-grow-1">
          <Col xs={12} sm={{ offset: 6, span: 6 }}>
            <Button onClick={handleClose} size="lg" className="w-100">
              Ok
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </>
  );
};

export default ZoneChargeModal;
