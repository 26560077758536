export const setToken = (token) => {
  setStorageItem("token", token);
};

export const getToken = () => {
  return parseJSON(window.localStorage.getItem("token"));
};

const setStorageItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
  window.dispatchEvent(new Event("local-storage"));
};

const parseJSON = (value) => {
  try {
    return value === "undefined" ? undefined : JSON.parse(value ?? "");
  } catch {
    console.log("parsing error on", { value });
    return undefined;
  }
};
