import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  FORM_NAV_INDEX,
  FORM_NAV_LIST,
  LABEL_LIST,
} from "../../helpers/constants";
import { getClasses } from "../../helpers/getClasses";
import { useNavigate } from "../../hooks";

const NavItem = ({ index, path }) => {
  const name = LABEL_LIST[index];
  const history = useHistory();
  const { pathname } = useLocation();
  const { handleNavigate } = useNavigate();

  const active = index <= FORM_NAV_INDEX[pathname];
  const current = index === FORM_NAV_INDEX[pathname];
  const classes = getClasses([
    "d-flex",
    "align-items-center",
    "mb-5",
    "side-nav-item",
    active && "active",
    current && "current",
  ]);
  return (
    <div
      onClick={() => handleNavigate(() => history.push(path))}
      className={classes}
    >
      <div className="rounded-circle center-flex side-nav-icon">
        {index + 1}
      </div>
      <div className="ml-3 side-nav-text">{name}</div>
    </div>
  );
};

const OrderNavBar = () => {
  return FORM_NAV_LIST.map((path, index) => (
    <NavItem key={path} path={path} index={index} />
  ));
};

export default OrderNavBar;
