import React from "react";
import { Form } from "react-bootstrap";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { useFormContext } from "react-hook-form";
import { selectTheme } from "../../helpers";
import { getClasses } from "../../helpers/getClasses";
import { useProductInput } from "../../hooks";
import useAddressData from "../../hooks/useAddressData";

export const AddressLookup = (props) => {
  const { resetField } = useFormContext();
  const buildAddressData = useAddressData();
  const handleChange = async (selection) => {
    if (selection) {
      await geocodeByPlaceId(selection.value.place_id).then((geo) => {
        selection.label = geo[0].formatted_address;
        buildAddressData(geo[0], selection);
      });
    } else {
      resetField("address_info");
    }
  };
  const { fieldProps, fieldState, required } = useProductInput({
    type: "text",
    onChange: handleChange,
    ...props,
  });
  const { error, invalid, valid } = fieldState;

  return (
    <Form.Group>
      <Form.Label className="text-muted">
        {"Address" + (required ? "*" : "")}
      </Form.Label>
      <GooglePlacesAutocomplete
        // ref={ref}
        withSessionToken
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        apiOptions={{ region: "ca", libraries: ["places", "geometry"] }}
        autocompletionRequest={{
          componentRestrictions: { country: ["ca"] },
          types: ["address"],
        }}
        minLengthAutocomplete={3}
        selectProps={{
          ...fieldProps,
          value: fieldProps.value || null,
          isClearable: true,
          placeholder: "Search...",
          ...selectTheme,
          valid,
          invalid,
          className: getClasses([
            !invalid && "is-valid",
            invalid && "is-invalid",
          ]),
        }}
      />
      <Form.Control.Feedback type="invalid">
        {error && <div>{error.message}</div>}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
