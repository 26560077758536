import ReactDOM from "react-dom";
import { scroller } from "react-scroll";
import { useLocation } from "react-router-dom";
import { isInternal } from "../helpers";
import { useFormContext } from "react-hook-form";

const VALIDATION_ROUTES = [
  "/new-order",
  "/photos",
  "/floorplans",
  "/matterport",
  "/sign-print",
  "/account-info",
  "/print",
];
const useNavigate = (type = "order_form") => {
  const { pathname } = useLocation();
  const { trigger, formRef, getValues } = useFormContext();

  const admin = localStorage.getItem("admin");
  const isValid = async (force = false) => {
    // return true;
    let valid = true;
    if (type === "order_form" && !isInternal()) {
      const zoneValid = getValues("address_info.zone_sku") !== "out-of-area";
      valid = valid && zoneValid;
    }
    const form_valid = VALIDATION_ROUTES.includes(pathname)
      ? await trigger()
      : true;
    valid = form_valid && valid;

    return !(!valid && !admin && !force);
  };
  const handleNavigate = async (callback, force) => {
    const valid = await isValid(force);

    if (!valid) {
      const first = ReactDOM.findDOMNode(
        formRef.current
      ).getElementsByClassName("is-invalid")[0];
      if (first && first.offsetParent !== null) {
        // offset parent is to prevent errors when scrolling to hidden items.
        const containerId = first.closest(".react-scrollbars-view").id;
        scroller.scrollTo(first.name || first.id, {
          containerId,
          smooth: true,
          duration: 500,
          offset: -100,
        });
      }
      return false;
    }
    callback();
  };
  return { handleNavigate };
};

export default useNavigate;
