import React, { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import ReactSelect from "react-select";
import Async from "react-select/async";
import Creatable from "react-select/creatable";
import { Element } from "react-scroll";
import { selectTheme } from "../../helpers";
import { getClasses } from "../../helpers/getClasses";
import { useProductInput } from "../../hooks";

export const Select = ({
  inline,
  groupClassName,
  loadOptions,
  options,
  subText,
  creatable,
  isLoading,
  ...props
}) => {
  const [loadedOptions, setLoadedOptions] = useState([]);
  const { fieldProps, fieldState, productFields } = useProductInput({
    ...props,
    parse: (val) => val?.value ?? null,
  });
  const { label } = productFields;
  const { error, invalid, isTouched } = fieldState || {};
  const valid = isTouched && !invalid;
  const SelectInput = useMemo(() => {
    if (loadOptions) {
      return Async;
    } else if (creatable) {
      return Creatable;
    } else {
      return ReactSelect;
    }
  }, [loadOptions, creatable]);
  const valueOptions = loadOptions ? loadedOptions : options;

  return (
    <Form.Group
      as={Element}
      name={fieldProps.name}
      className={getClasses([inline && "d-inline-block", groupClassName])}
    >
      {label && <Form.Label className="text-muted">{label}</Form.Label>}
      <SelectInput
        defaultOptions
        {...fieldProps}
        {...selectTheme}
        options={options}
        id={fieldProps.name}
        loadOptions={
          loadOptions
            ? async (value) => {
                const options = await loadOptions(value);
                setLoadedOptions(options);
                return options;
              }
            : undefined
        }
        value={valueOptions.find((o) => o.value === fieldProps.value) || null}
        onBlur={(e) => e.preventDefault()}
        valid={valid}
        invalid={invalid}
        className={getClasses([valid && "is-valid", invalid && "is-invalid"])}
      />
      {(!isTouched || !error) && subText && <Form.Text>{subText}</Form.Text>}
      <Form.Control.Feedback type="invalid">
        {error && <div>{error.message}</div>}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
