import { library } from "@fortawesome/fontawesome-svg-core";
import { upVT, upFloorplan, upDollhouse } from "../components/common/Icons.js";

import {
  faBars,
  faMapMarkerAlt,
  faShower,
  faBed,
  faLayerGroup,
  faRedo,
  faArrowRight,
  faArrowLeft,
  faExpand,
  faArrowToTop,
  faCheck,
  faTimes,
  faRuler,
  faArrowDown,
} from "@fortawesome/pro-light-svg-icons";
import {
  faCamera,
  faFilmAlt,
  faHouse,
  faMapMarkedAlt,
  faEye,
  faEyeSlash,
  faArrowLeft as farArrowLeft,
  faExclamationCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { faPlayCircle } from "@fortawesome/pro-duotone-svg-icons";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";

library.add(
  faEye,
  faEyeSlash,
  faHouse,
  faMapMarkedAlt,
  faBars,
  faArrowToTop,
  faFilmAlt,
  faMapMarkerAlt,
  faShower,
  faBed,
  faLayerGroup,
  faRedo,
  faCamera,
  faArrowRight,
  faArrowLeft,
  faArrowDown,
  faExpand,
  faPlayCircle,
  farArrowLeft,
  faCheck,
  faTimes,
  faPlay,
  faRuler,
  faExclamationCircle,
  upVT,
  upFloorplan,
  upDollhouse
);
