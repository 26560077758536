import { isFunction, isNil } from "lodash";
import { useEffect, useMemo } from "react";
import { useController, useFormContext } from "react-hook-form";
import { useProduct } from "../adapters/uplist";
import { isInternal, toCurrency } from "../helpers";
import { RADIO_SKUS } from "../helpers/constants";
import { useEvent } from "./useEvent";

export const sanitizeInputRestProps = ({
  sku,
  label,
  onChange,
  custom_price,
  additional_price,
  description,
  discount,
  validate,
  required,
  type,
  name,
  defaultValue,
  rules,
  shouldUnregister,
  disabled,
  internalRequired,
  ...rest
}) => rest;

const addRequired = (required, label) => {
  return label + (required ? "*" : "");
};

const getMinimumRule = (minimum = 0) => {
  let message = "Must be a positive number";
  if (minimum === 1) message += " greater than 0";
  if (minimum > 1) message = `A minimum of ${minimum} is required`;
  return {
    min: {
      value: minimum,
      message: `${message}.`,
    },
  };
};

export const useProductInput = (props) => {
  const {
    sku,
    label,
    onChange: initialOnChange,
    custom_price: initialPriceString,
    additional_price = 0,
    description,
    discount,
    defaultValue,
    validate,
    required: externalRequired,
    internalRequired,
    type,
    parse,
    rules,
    minimum,
    ...options
  } = props;
  const required =
    (!isInternal() && externalRequired) || (isInternal() && !!internalRequired);
  const { data: product } = useProduct(sku);
  const { setValue, getValues } = useFormContext();
  const min_rule =
    type === "number"
      ? getMinimumRule(isFinite(minimum) ? minimum : required ? 1 : 0)
      : null;

  const { field, fieldState, formState } = useController({
    name: sku,
    defaultValue,
    rules: {
      required:
        typeof required === "string" ? required : required && "Required",
      validate: async (value, values) => {
        if (!validate) return true;
        const error = await validate(value, values, props);
        if (!error) return true;
        return error;
      },
      ...min_rule,
      ...rules,
    },
    ...options,
  });

  useEffect(() => {
    if (!isNil(defaultValue) && getValues(sku) === undefined) {
      setValue(sku, defaultValue);
    }
  }, [defaultValue, sku, setValue, getValues]);

  const radioSkus = useMemo(() => {
    return RADIO_SKUS.flatMap((sku_array) =>
      sku_array.includes(sku) ? sku_array.filter((s) => s !== sku) : []
    );
  }, [sku]);

  const price = additional_price + (product?.price || 0);
  const price_string =
    initialPriceString ||
    (initialPriceString !== false && toCurrency(price, "+"));
  const onChange = useEvent((event) => {
    const eventOrValue = ["checkbox"].includes(props.type)
      ? event.target.checked
      : event?.target?.value ?? event;
    if (eventOrValue && radioSkus.length) {
      radioSkus.forEach((sku) => {
        setValue(sku, false);
      });
    }
    let parseValue = parse ? parse(eventOrValue) : eventOrValue;
    if (props.type === "number")
      parseValue = parseValue !== "" ? parseInt(parseValue) : parseValue;
    field.onChange(parseValue);
    if (initialOnChange) initialOnChange(event, parseValue, sku);
  });
  const fieldProps = {
    ...field,
    type,
    onChange,
    ...sanitizeInputRestProps(options),
  };
  const productFields = {
    price: price_string,
    description: description || product?.description,
  };
  if (isFunction(label)) {
    productFields.label = addRequired(required, label(product?.name, sku));
  } else if (label !== false) {
    productFields.label = addRequired(required, label || product?.name);
  }
  if (productFields.label && isFinite(minimum)) {
    productFields.label += ` (Minimum ${minimum})`;
  }
  return {
    fieldProps,
    fieldState,
    formState,
    required,
    product,
    productFields,
  };
};
