import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

ReactDOM.render(
  // Strict mode breaks react-advanced-form
  // <React.StrictMode>
  <Suspense fallback={<div></div>}>
    <App />
  </Suspense>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
