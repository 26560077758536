import React, { useState } from "react";
import { Carousel, CarouselItem } from "..";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { useStatus } from "../../hooks";

const Photos = () => {
  const { listing } = useListingState();
  const images = listing.photos.sort((a, b) => a.position - b.position);
  const [loading, setLoading] = useState(images.length > 0);
  useStatus({ name: "Photos", loading });
  if (images.length === 0) return null;
  const options = {
    wrapAround: images.length > 2,
  };
  return (
    <Carousel id="Photos" setLoading={setLoading} options={options}>
      {images.map((image, i) => (
        <CarouselItem
          key={i}
          url={image.image_urls.large}
          index={i}
          type="photo"
        />
      ))}
    </Carousel>
  );
};

export default Photos;
