import React, { Children, useEffect, useRef } from "react";
import { useFormContext } from "react-hook-form";
import { isInternal } from "../../helpers";
import useProductsSelected from "../../hooks/useProductsSelected";

const AddOnSection = ({
  children,
  title,
  show_skus = [],
  addon_skus = [],
  hideInternal,
}) => {
  const showRef = useRef(show_skus);
  const addonRef = useRef(addon_skus);
  const selected = useProductsSelected(showRef.current);
  const show = selected || (isInternal() && !hideInternal);
  const first = useRef(false);
  const { unregister } = useFormContext();

  useEffect(() => {
    addonRef.current = addon_skus;
  }, [addon_skus]);

  useEffect(() => {
    if (!show && first.current) {
      unregister(addonRef.current);
    } else {
      first.current = true;
    }
  }, [show, unregister]);

  if (!show) return null;
  const hasChildren = !!Children.count(children);
  return (
    <>
      {title && hasChildren && <h2 className="mb-4">{title}</h2>}
      {children}
    </>
  );
};

export default AddOnSection;
