import { useWatch } from "react-hook-form";

const useFullAddress = () => {
  const address_info = useWatch({ name: "address_info" });
  const street_address = [];
  const address = [];
  let full_address = "";
  if (address_info) {
    const address_parts = [
      "street_number",
      "street_name",
      "street_suffix",
      "street_direction",
      "region",
    ];
    for (let key of address_parts) {
      if (address_info[key]) {
        if (key !== "region") address.push(address_info[key]);
        street_address.push(
          key === "region" ? address_info[key].label : address_info[key]
        );
      }
    }

    if (address_info["unit_number"]) {
      full_address += `${address_info["unit_number"]}-`;
    }
    full_address += address.join(" ");
  }
  return { street_address: street_address.join(" "), full_address };
};

export default useFullAddress;
