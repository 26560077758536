import React from "react";
import { getClasses } from "../../helpers/getClasses";

const AspectRatio = ({ ratio, children, className }) => {
  return (
    <div
      className={getClasses(["ratio-wrapper", className])}
      style={{ paddingBottom: `${(1 / ratio) * 100}%` }}
    >
      {children}
    </div>
  );
};

export default AspectRatio;
