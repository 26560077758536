import useWindowSize from "./useWindowSize";

const useMaxHeight = (sizes) => {
  const {
    isNotSmall,
    windowDimensions: { height },
  } = useWindowSize();
  const max_height = isNotSmall ? sizes[1] : sizes[0];
  return height >= max_height ? max_height : height;
};

export default useMaxHeight;
