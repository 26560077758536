import React, { createContext, useContext, useReducer, useState } from "react";

const createDataContext = (reducer, actions, defaultValue) => {
  const Context = createContext();
  const ActionsContext = createContext();

  const useStateContext = () => useContext(Context);
  const useActionsContext = () => useContext(ActionsContext);

  const Provider = ({ children, value }) => {
    const [state, dispatch] = useReducer(reducer, {
      ...defaultValue,
      ...value,
    });
    function createActions() {
      const boundActions = {};
      for (let key in actions) {
        boundActions[key] = actions[key](dispatch);
      }
      return boundActions;
    }
    const [boundActions] = useState(createActions());

    return (
      <Context.Provider value={{ ...state }}>
        <ActionsContext.Provider value={boundActions}>
          {children}
        </ActionsContext.Provider>
      </Context.Provider>
    );
  };
  return {
    Provider,
    Context,
    ActionsContext,
    useStateContext,
    useActionsContext,
  };
};

export default createDataContext;
