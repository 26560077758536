import { useEffect } from "react";
import { useActionsContext } from "../context/StatusContext";

const useStatus = ({ name, loading, error, listing }) => {
  const { setError, setLoading, setStatusListing } = useActionsContext();
  useEffect(() => {
    if (error) {
      setError(name, error.message);
    }
  }, [name, error, setError]);
  useEffect(() => {
    setLoading(name, loading);
  }, [name, loading, setLoading]);
  useEffect(() => {
    if (listing) {
      setStatusListing(listing);
    }
  }, [listing, setStatusListing]);
};

export default useStatus;
