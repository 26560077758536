import { Button as BSButton } from "react-bootstrap";
import { useProductInput } from "../../hooks";
import { useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { isNil } from "lodash";

export const Button = (props) => {
  const { sku, defaultValue } = props;
  const { setValue } = useFormContext();
  useEffect(() => {
    /**
     * For some reason defaultValue doesn't properly trigger useWatch
     * when the field is unregistered and re-registered.
     * So we are manually triggering it when the button is re-mounted.
     * It currently only really effects the buttons used in FP and Virtual Staging addons.
     */
    if (sku && !isNil(defaultValue)) {
      setValue(sku, defaultValue);
    }
  }, [sku, setValue, defaultValue]);

  const { fieldProps, productFields } = useProductInput(props);
  const { label } = productFields;
  const { value, onChange } = fieldProps;

  const handleClick = () => {
    onChange(true);
  };

  return (
    <BSButton
      variant={value ? "primary" : "outline-primary"}
      onClick={handleClick}
      block
    >
      {label}
    </BSButton>
  );
};
