import { inRange } from "lodash";
import { useCallback } from "react";
import { useWatch } from "react-hook-form";

export const useInSqftRange = () => {
  const sqft = useWatch({ name: "order_info.sq_ft" });
  return useCallback(
    (range) => {
      const [min, max] = range.split("-").map((n) => parseInt(n));
      return inRange(sqft, min, max + 1);
    },
    [sqft]
  );
};
