import React from "react";
import styled from "styled-components";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { StyledCol, StyledH1, StyledRow, StyledSection } from "..";
import Map from "./Map";
const Text = styled.p`
  color: ${(p) => p.theme.text.main};
  font-size: ${(p) => p.theme.fonts.size.p};
`;

const Details = () => {
  const { listing } = useListingState();
  return (
    <StyledSection id="details">
      <StyledRow>
        {listing.description && (
          <StyledCol md={6}>
            <StyledH1>Listing Details</StyledH1>
            {listing.description.split("\n").map((str, i) => (
              <Text key={i}>{str}</Text>
            ))}
          </StyledCol>
        )}
        <StyledCol md={listing.description ? 6 : 12}>
          <Map />
        </StyledCol>
      </StyledRow>
    </StyledSection>
  );
};

export default Details;
