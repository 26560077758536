import { useCallback } from "react";
import { getLatLng } from "react-google-places-autocomplete";
import { useFormContext } from "react-hook-form";
import uplist from "../adapters/uplist";
import { useTravelZone } from "../context/TravelZoneContext";
import {
  DIRECTION_ARRAY,
  STREET_SUFFIXES,
  STREET_SUFFIX_CONVERSION,
} from "../helpers/constants";

const getStreetDirection = (address) => {
  let address_array = address.split(" ");
  let last = address_array.pop().replace(/\W/g, "");
  if (DIRECTION_ARRAY.includes(last)) {
    const results = getStreetSuffix(address_array);
    return { street_direction: last, ...results };
  } else {
    return getStreetSuffix(address_array.concat(last));
  }
};

const getStreetSuffix = (address_array) => {
  let last = address_array.pop();
  if (STREET_SUFFIXES.includes(last)) {
    const street_suffix = STREET_SUFFIX_CONVERSION[last] || last;
    return { street_suffix, street_name: address_array.join(" ") };
  } else {
    return { street_name: address_array.concat(last).join(" ") };
  }
};

const useAddressData = () => {
  const { setValue, getValues, setError } = useFormContext();
  const { setTravelZone } = useTravelZone();

  const setErrors = useCallback(
    (selection) => {
      const required_keys = ["street_number", "street_name", "region"];
      const missing_keys = required_keys.filter(
        (key) => !getValues(`address_info.${key}`)
      );
      if (missing_keys.length === 0) return;

      missing_keys.forEach((key) => {
        let message;
        switch (key) {
          case "street_number":
            message = /^-?\d+$/.test(selection.value.terms[0].value)
              ? "We couldn't find a home with the house number provided. Please confirm the house number."
              : "No house number provided.";
            break;
          case "street_name":
            message = "We couldn't find a matching street name.";
            break;
          case "region":
            message =
              "We couldn't find what area this address is in. Please select the closest option.";
            break;
          default:
            message =
              "Something went wrong. Please confirm the address details.";
        }
        if (message)
          setError(`address_info.${key}`, { type: "required", message });
      });
    },
    [setError, getValues]
  );

  const buildAddressData = async (geo, selection) => {
    const getGeoValue = (key) => {
      const value = geo.address_components.filter((component) =>
        component.types.includes(key)
      );
      return value[0]?.[key === "route" ? "long_name" : "short_name"];
    };

    const getRegion = () => {
      let region = getGeoValue("locality");
      // For some dumb reason Langford is not a "locality" which is what we usually want.
      const level3 = getGeoValue("administrative_area_level_3");
      if (level3 === "Langford") region = level3;
      region = region || level3;
      region = region || getGeoValue("administrative_area_level_2");
      region = region || getGeoValue("administrative_area_level_1");
      return uplist
        .get(`/regions/find_by_name?name=${region}`)
        .then((res) => res.data);
    };

    setTravelZone(geo.geometry.location);
    setValue("address_info.street_number", getGeoValue("street_number"));
    const route = getGeoValue("route");
    const { street_suffix, street_direction, street_name } =
      getStreetDirection(route);
    setValue("address_info.street_suffix", street_suffix);
    setValue("address_info.street_direction", street_direction);
    setValue("address_info.street_name", street_name);
    const region = await getRegion();
    setValue("address_info.region", region);
    const { lat, lng } = await getLatLng(geo);
    setValue("address_info.latlng", { lat, lng });
    setErrors(selection);
  };
  return buildAddressData;
};

export default useAddressData;
