import React, { useEffect, useState } from "react";
import { some } from "lodash";
import { useStateContext } from "../../context/StatusContext";
import styled, { keyframes } from "styled-components";
import uplistLogo from "../../assets/images/uplist-logo-color.png";
import house from "../../assets/images/disappearing-house.gif";
import { presents } from "../../helpers/presents";
import { MobileSummary } from "..";

const ellipsis = keyframes`
  to {
    width: .9em;
  }`;

const ListingDetailsWrapper = styled.div`
  transition: opacity 1s;
  padding: 15px;
  ${"" /* max-width: 100%; */}
`;

const AbsoluteCentered = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Overlay = styled(AbsoluteCentered)`
  position: fixed;
  background-color: #f9f7f3;
  z-index: 10;
  opacity: 1;
  transition: opacity 1s;
`;

const LoaderWrapper = styled(AbsoluteCentered)`
  padding: 0 15px;
  transition: opacity 1s;
`;

const Image = styled.img`
  max-width: 100%;
`;
const Agent = styled.div`
  color: #fc503b;
  font-size: ${(p) => (p.theme.isNotSmall ? "4em" : "2em")};
  text-align: ${(p) => (p.theme.isNotSmall ? "left" : "center")};
  font-family: "VisbyCF-Bold", sans-serif;
`;
const Presents = styled(Agent)`
  font-family: "VisbyCF-Medium", sans-serif;
  line-height: 1;
`;

const Address = styled.div`
  font-family: "VisbySlabCF-DemiBold", sans-serif;
  font-size: ${(p) => (p.theme.isNotSmall ? "4em" : "2em")};
  text-align: ${(p) => (p.theme.isNotSmall ? "left" : "center")};
  margin-bottom: 15px;
  color: #0f1b34;
`;
const LoadingWrapper = styled.div`
  margin-top: 15px;
  font-family: "VisbySlabCF-DemiBold", sans-serif;
  font-size: 1.5em;
  text-align: center;
  color: #0f1b34;
`;

const LoadingText = styled.div`
  display: inline-block;
`;

const EllipsisWrapper = styled.div`
  display: inline-block;
  min-width: 1.25em;
  text-align: left;
  vertical-align: bottom;
`;
const Ellipsis = styled.div`
  overflow: hidden;
  -webkit-animation: ${ellipsis} steps(4, end) 2000ms infinite;
  animation: ${ellipsis} steps(4, end) 2000ms infinite;
  width: 0px;
`;
const Message = styled.div`
  font-size: ${(p) => (p.theme.isNotSmall ? "2em" : "1.5em")};
  font-family: "VisbyCF-Bold", sans-serif;
  color: #fc503b;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.75);
  padding: 10px;
  border-radius: 20px;
`;

const MessageWrapper = styled.div`
  z-index: 2;
`;

const HouseWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const useDelay = (trigger, delay = 1000) => {
  const [delayed, setDelayed] = useState(true);
  useEffect(() => {
    if (trigger) {
      setTimeout(() => setDelayed(false), delay);
    }
  }, [trigger, delay]);
  return delayed;
};

const Loading = () => {
  return (
    <>
      <LoadingText>Loading</LoadingText>
      <EllipsisWrapper>
        <Ellipsis>...</Ellipsis>
      </EllipsisWrapper>
    </>
  );
};

const ListingDetails = ({ listing }) => {
  return (
    <ListingDetailsWrapper style={{ opacity: listing ? 1 : 0 }}>
      {listing && (
        <>
          <Agent>{listing.agent_names}</Agent>
          <Presents>{presents(listing)}</Presents>
          <Address>{listing.address}</Address>
          <MobileSummary listing={listing} />
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        </>
      )}
    </ListingDetailsWrapper>
  );
};

const InitialLoader = ({ data }) => {
  const { listing, success, message } = data || {};
  return (
    <LoaderWrapper style={{ opacity: listing ? 0 : 1 }}>
      {!success && message && (
        <HouseWrapper HouseWrapper>
          <Image src={house} />
        </HouseWrapper>
      )}
      <MessageWrapper>
        <Image src={uplistLogo} alt="UplistLogo" />
        <Message>{!success && message ? message : <Loading />}</Message>
      </MessageWrapper>
    </LoaderWrapper>
  );
};

const StatusOverlay = () => {
  const { loading, data } = useStateContext();
  const { listing } = data || {};
  let isLoading = loading === undefined || some(loading, { isLoading: true });
  const infoDelay = useDelay(listing);
  const overlayDelay = useDelay(!isLoading);
  var classMethod = isLoading ? "add" : "remove";
  document.documentElement.classList[classMethod]("overlay");
  if (!overlayDelay && data.success) return null;
  return (
    <Overlay style={{ opacity: isLoading || infoDelay ? 1 : 0 }}>
      <InitialLoader data={data} />
      <ListingDetails listing={listing} />
    </Overlay>
  );
};

export default StatusOverlay;
