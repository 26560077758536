import { Badge, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useUser } from "../../context/UserContext";

const TagList = ({
  tags,
  alert_types = [],
  LabelComponent = Form.Label,
  className,
  name,
  type,
}) => {
  const filtered_tags = tags?.filter(
    ({ types }) => !type || types.includes(type)
  );
  if (!filtered_tags?.length) return null;
  return (
    <Col>
      <LabelComponent className={className}>
        {name}'s {type} Tags
      </LabelComponent>
      <ListGroup>
        {filtered_tags.map((tag) => (
          <ListGroup.Item
            key={tag.id}
            className="d-flex justify-content-between align-items-center"
          >
            <div>{tag.notes}</div>
            <div>
              {tag.types.map((type) => (
                <Badge
                  key={type}
                  variant={alert_types.includes(type) ? "danger" : "secondary"}
                  className="ml-1"
                  pill
                >
                  {type}
                </Badge>
              ))}
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </Col>
  );
};

export const TagLists = (props) => {
  const { user } = useUser();
  if (!user || (!user.tags && !user.team_tags)) return null;
  return (
    <Row className="mb-4">
      <TagList tags={user?.team_tags} name={user.team_name} {...props} />
      <TagList tags={user?.tags} name={user.name} {...props} />
    </Row>
  );
};
