import useWindowSize from "./useWindowSize";

const useListingTheme = () => {
  const { isXLarge, isLarge, isNotSmall } = useWindowSize();
  const colors = {
    mirage: "#0f1b34",
    mirage_hover: "#1c315f",
    spring_wood: "#f9f7f3",
    cararra: "#EBE9E2",
    carmine: "#fc503b",
    carmine_hover: "#cf3522",
    carmine_press: "#a13427",
    carmine_muted: "#ffb995",
    curious: "#2286d2",
    curious_hover: "#0a5fa1",
    curious_press: "#034679",
    curious_muted: "#8ab6d8",
    curious_soft: "#e8f6f8",
    trout: "#4a505c",
    trout_hover: "#373d47",
    trout_press: "#262c35",
    trout_muted: "#979ba3",
    trout_soft: "#f9f7f3",
    aquamarine: "#71dcdd",
    aquamarine_hover: "#3cb7bd",
    aquamarine_press: "#34909d",
    aquamarine_muted: "#d6f6f3",
    white: "#ffffff",
  };

  const theme = {
    primary: {
      main: colors.mirage,
      hover: colors.mirage_hover,
      press: colors.mirage,
      disabled: colors.trout,
      text: colors.white,
    },
    accent: {
      main: colors.carmine,
      hover: colors.carmine_hover,
      press: colors.carmine_press,
      disabled: colors.carmine_muted,
    },
    primary_light: {
      main: colors.curious,
      hover: colors.curious_hover,
      press: colors.curious_press,
      disabled: colors.curious_muted,
    },
    text: {
      main: colors.trout,
      light: colors.trout_muted,
      dark: colors.mirage,
      white: colors.white,
    },
    background: {
      light: colors.spring_wood,
      light_border: colors.cararra,
      dark: colors.mirage,
    },
    fonts: {
      visby: {
        regular: '"VisbyCF-Regular",sans-serif;',
        bold: '"VisbyCF-Bold",sans-serif;',
        demibold: '"VisbyCF-DemiBold",sans-serif;',
        medium: '"VisbyCF-Medium",sans-serif;',
        slab_bold: '"VisbySlabCF-Bold",sans-serif;',
        slab_demibold: '"VisbySlabCF-DemiBold",sans-serif;',
      },
      nunito: {
        regular: '"nunito-sans-regular",sans-serif;',
      },
      size: {
        text: {
          xs: "12px",
          sm: "14px",
          md: "18px",
          lg: "24px",
          xl: "32px",
        },
        header: {
          sm: "26px",
          md: "32px",
          lg: "36px",
          xl: "40px",
        },
        h1: isLarge ? "32px" : "20px",
        p: isLarge ? "18px" : "16px",
      },
    },
    spacing: {
      standard: "15px",
      wide: isLarge ? "30px" : "15px",
      gutter: isLarge ? "15px" : "0",
    },
    screen_size: {
      xs: "0",
      sm: "576px",
      md: "768px",
      lg: "992px",
      xl: "1200px",
    },
    isNotSmall,
    isLarge,
    isXLarge,
  };
  return theme;
};

export default useListingTheme;
