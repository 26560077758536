import { isFinite } from "lodash";
import { useWatch } from "react-hook-form";
import { useProducts } from "../adapters/uplist";

const filterMapObject = (obj, filter_callback, map_callback = (e) => e) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([k, v]) => filter_callback(k, v))
      .map(([k, v]) => map_callback(k, v))
  );
};

const filterProduct = (key, val) => !!val && /^\d+$/.test(key);

const useSelectedProductValues = () => {
  const { data: products } = useProducts();
  const formValues = useWatch() || {};
  const values = filterMapObject(formValues, filterProduct, (key, val) => {
    const value = typeof val === "object" ? val.value : val;
    return [key, value];
  });
  const prices = filterMapObject(formValues, filterProduct, (key, val) => {
    const qty = isFinite(val) ? val : val === true ? 1 : 0;
    const price = qty * products[key].price;
    return [key, price];
  });
  return { values, prices };
};

export default useSelectedProductValues;
