import React from "react";
import styled from "styled-components";
import { StyledCol, StyledRow } from "../common/Wrappers";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IconBarWrapper = styled(StyledRow)`
  color: ${(p) => p.theme.text.main};
  font-size: ${(p) => p.theme.fonts.size.text.xs};
  font-family: ${(p) => p.theme.fonts.visby.demibold};
  margin-bottom: ${(p) => p.theme.spacing.wide};
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${(p) => p.theme.text.light};
  font-size: 20px;
  margin: 5px;
`;
const useIconItems = () => {
  const { listing } = useListingState();

  return [
    {
      icon: ["fal", "map-marker-alt"],
      value: listing.region,
    },
    {
      icon: ["fal", "shower"],
      value: listing.baths_dec > 0 ? listing.baths_dec : listing.baths,
      text: "BATHROOMS",
    },
    {
      icon: ["fal", "bed"],
      value: listing.beds,
      text: "BEDROOMS",
    },
    {
      icon: ["fal", "layer-group"],
      value: listing.floors,
      text: "FLOORS",
    },
  ];
};
const IconWrapper = styled(StyledCol)`
  &&& {
    display: flex;
    align-items: center;
    ${(p) => (p.theme.isLarge ? "margin: 10px 0;" : "padding: 5px 0;")};
    border-style: solid;
    border-color: ${(p) => p.theme.background.light_border};
    border-width: ${(p) => (p.theme.isLarge ? "0" : "0 0 1px 0")};
  }
`;

const Value = styled.span`
  margin-left: 5px;
`;

const IconItem = ({ item }) => {
  const { icon, value, text } = item;
  if (!value) return null;
  return (
    <IconWrapper md={6} lg="auto">
      <Icon icon={icon} />
      <Value>{value}</Value>
      <Value>{text}</Value>
    </IconWrapper>
  );
};

const IconBar = () => {
  const icon_items = useIconItems();
  return (
    <IconBarWrapper>
      {icon_items.map((item, i) => (
        <IconItem key={i} item={item} />
      ))}
    </IconBarWrapper>
  );
};

export default IconBar;
