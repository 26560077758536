import { useFormDetailsState } from "../context/FormDetailsContext";

const useChargeType = () => {
  const { charge_type } = useFormDetailsState();
  const isChargeSet = charge_type !== undefined;
  const isAutoCharge = charge_type === 3;
  const isInvoiceOffice = charge_type === 2;
  return { isChargeSet, isAutoCharge, isInvoiceOffice };
};

export default useChargeType;
