import React from "react";
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  Svg,
  Path,
  Image,
} from "@react-pdf/renderer";

import { presents, getInfoItems } from "../../helpers";
import {
  faBed,
  faMapMarkerAlt,
  faShower,
  faLayerGroup,
} from "@fortawesome/pro-light-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import realfotoLogo from "../../assets/images/realfoto-logo-white.png";
import uplistLogo from "../../assets/images/uplist-logo-white.png";

import VisbyCFRegular from "../../assets/fonts/visby-cf/regular/visby-cf.ttf";
import VisbyCFMedium from "../../assets/fonts/visby-cf/medium/visby-cf-medium.ttf";
import VisbyCFDemiBold from "../../assets/fonts/visby-cf/demibold/visby-cf-demi-bold.ttf";
import VisbyCFBold from "../../assets/fonts/visby-cf/bold/visby-cf-bold.ttf";
import NunitoSans from "../../assets/fonts/nunito-sans/regular/NunitoSans-Regular.ttf";

Font.register({
  family: "VisbyCF",
  fontStyle: "normal",
  fontWeight: "normal",
  fonts: [
    { src: VisbyCFMedium },
    { src: VisbyCFRegular, fontWeight: "lighter" },
    { src: VisbyCFDemiBold, fontWeight: "bold" },
    { src: VisbyCFBold, fontWeight: "bolder" },
  ],
});

Font.register({
  family: "nunito-sans",
  src: NunitoSans,
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    fontFamily: "nunito-sans",
    paddingBottom: 95,
  },
  section: {
    margin: "0 30px",
    padding: "15px 0",
  },
  row: {
    flexDirection: "row",
  },
  center: {
    alignItems: "center",
  },
  top_bar: {
    padding: 30,
    marginBottom: 15,
    fontFamily: "VisbyCF",
    fontWeight: "lighter",
  },
  footer: {
    padding: 30,
    position: "absolute",
    height: 95,
    bottom: 0,
    width: "100%",
  },
  section_line: {
    borderBottom: "1px solid #EBE9E2",
  },
  info_item: {
    flexGrow: 1,
    borderRightStyle: "solid",
    borderColor: "#EBE9E2",
    alignItems: "center",
    fontFamily: "VisbyCF",
    fontWeight: "lighter",
  },
  info_title: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  info_value: {
    fontWeight: "lighter",
  },
  icon_text: {
    fontWeight: "bold",
    fontSize: "10px",
    marginLeft: 10,
  },
  description: {
    fontSize: "12px",
  },
  prec: {
    fontSize: 12,
    fontWeight: "bolder",
    fontFamily: "VisbyCF",
  },
  agent_info: {
    fontSize: 16,
    paddingBottom: 15,
  },
  agent_name: {
    fontSize: 18,
    fontFamily: "VisbyCF",
    fontWeight: "bolder",
  },
  phone_type: {
    fontSize: 10,
    textTransform: "capitalize",
    paddingLeft: "10px",
  },
  presented_by: {
    paddingBottom: 15,
    fontFamily: "VisbyCF",
    fontWeight: "normal",
    textAlign: "center",
  },
});

const TopBar = ({ listing, theme }) => {
  return (
    <View
      style={[styles.top_bar, { backgroundColor: theme.background.dark }]}
      fixed
    >
      <Text style={{ color: theme.accent.main, fontWeight: 700 }}>
        <Text>{`${listing.agent_names} ${presents(listing)}`}</Text>
      </Text>
      <Text
        style={{ color: theme.text.white, fontWeight: 500, letterSpacing: 2 }}
      >
        {listing.address}
      </Text>
    </View>
  );
};

const InfoItem = ({ title, value, last, theme }) => {
  if (!value) return null;
  return (
    <View style={[styles.info_item, { borderRightWidth: last ? 0 : 1 }]}>
      <View>
        <Text style={[styles.info_title, { color: theme.text.light }]}>
          {title}
        </Text>
        <Text style={[styles.info_value]}>{value}</Text>
      </View>
    </View>
  );
};

const Summary = (props) => {
  const { listing } = props;
  const info_items = getInfoItems(listing);
  return (
    <View
      style={[
        styles.section,
        styles.section_line,
        styles.row,
        { paddingTop: 0 },
      ]}
    >
      {info_items.map((item, i) => (
        <InfoItem
          {...props}
          key={i}
          title={item.title}
          value={item.value}
          last={i + 1 === info_items.length}
        />
      ))}
    </View>
  );
};
const iconItems = (listing) => {
  return [
    {
      icon: faMapMarkerAlt,
      value: listing.region,
      text: "",
    },
    {
      icon: faShower,
      value: listing.baths_dec > 0 ? listing.baths_dec : listing.baths,
      text: "BATHROOMS",
    },
    {
      icon: faBed,
      value: listing.beds,
      text: "BEDROOMS",
    },
    {
      icon: faLayerGroup,
      value: listing.floors,
      text: "FLOORS",
    },
  ];
};

const IconItem = ({ item, theme }) => {
  const {
    icon: { icon },
    value,
    text,
  } = item;
  return (
    <View style={[styles.row, styles.info_item]}>
      <Svg width={20} height={20} viewBox={`0 0 ${icon[0]} ${icon[1]}`}>
        <Path d={icon[4]} fill={theme.text.light} />
      </Svg>
      <Text style={[styles.icon_text]}>{`${value} ${text}`}</Text>
    </View>
  );
};

const IconBar = (props) => {
  const { listing } = props;
  return (
    <View style={[styles.section, styles.center, { flexGrow: 1 }]}>
      <View style={{ height: "100%" }}>
        {iconItems(listing).map((item) => (
          <IconItem key={item.text} {...props} item={item} />
        ))}
      </View>
    </View>
  );
};

const Map = ({ listing, theme }) => {
  const { icon } = faHome;
  const center = `${listing.latitude},${listing.longitude}`;
  const url = `https://maps.googleapis.com/maps/api/staticmap?center=${center}&zoom=15&size=600x600&scale=2&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  return (
    <View style={[{ position: "relative" }]}>
      <Image src={url} style={{ height: "100%", objectFit: "cover" }} />
      <Svg
        viewBox={`0 0 ${icon[0]} ${icon[1]}`}
        style={{
          position: "absolute",
          top: "46%",
          left: "46%",
          width: "8%",
          height: "8%",
        }}
      >
        <Path d={icon[4]} fill={theme.accent.main} />
      </Svg>
    </View>
  );
};

const LeadImage = ({ listing }) => {
  const lead_image = listing.photos[0]?.image_urls.medium;
  if (!lead_image) return null;
  return (
    <View>
      <Image src={lead_image} style={{ height: "250px" }} cache={false} />
    </View>
  );
};

const Description = ({ listing }) => {
  return (
    <View style={[styles.section, styles.description, styles.section_line]}>
      <Text>{listing.description}</Text>
    </View>
  );
};

const Details = (props) => {
  const { listing } = props;
  return (
    <View
      style={[styles.section, styles.section_line, styles.row]}
      wrap={false}
    >
      <View style={{ width: "75%" }}>
        <View>
          {listing.hotsheet_features.map((feature) => (
            <View
              key={feature.title}
              style={{ fontSize: "12px", marginBottom: "4px" }}
            >
              <Text>
                <Text
                  style={{ fontFamily: "VisbyCF" }}
                >{`${feature.title}: `}</Text>
                <Text>{` ${feature.value}`}</Text>
              </Text>
            </View>
          ))}
        </View>
      </View>
      <Map {...props} />
    </View>
  );
};

const PhoneNumbers = ({ object }) => (
  <>
    {object &&
      object.phone_numbers.map((phone_number, i) => (
        <Text key={i}>
          <Text>{phone_number.number}</Text>
          <Text style={styles.phone_type}>{`  ${phone_number.type}`}</Text>
        </Text>
      ))}
  </>
);

const Emails = ({ object }) => (
  <>
    {object &&
      object.display_emails.map(({ email }, i) => (
        <Text key={email}>{email}</Text>
      ))}
  </>
);

const AgentInfo = ({ listing, theme }) => (
  <>
    <View style={[styles.section, { paddingBottom: 0 }]}>
      <Text style={styles.presented_by} fixed>
        Presented By
      </Text>
      <View style={[styles.row, { flexWrap: "wrap" }]}>
        {listing.agents.map((agent) => (
          <View
            key={agent.name}
            style={[
              styles.agent_info,
              { width: listing.agents.length > 1 ? "50%" : "100%" },
            ]}
            wrap={false}
          >
            <Text
              style={[styles.agent_name, { color: theme.primary_light.press }]}
            >
              {agent.name}
            </Text>
            <Text>{agent.office_name}</Text>
            {agent.prec && (
              <Text style={[styles.prec, { color: theme.text.light }]}>
                PERSONAL REAL ESTATE CORPORATION
              </Text>
            )}
            <PhoneNumbers object={agent} />
            <PhoneNumbers object={listing.team} />
            <Emails object={agent} />
            <Emails object={listing.team} />
          </View>
        ))}
      </View>
    </View>
  </>
);

const Footer = ({ listing, theme }) => {
  return (
    <View
      style={[
        styles.footer,
        styles.row,
        {
          backgroundColor: theme.background.dark,
          justifyContent: "space-between",
          alignItems: "center",
        },
      ]}
      wrap={false}
      fixed
    >
      <Image src={realfotoLogo} style={{ height: 30 }} />
      <View
        style={{ color: theme.text.white, alignItems: "center", fontSize: 12 }}
      >
        <Text>Content Provided By</Text>
        <Text>realfoto.ca | uplist.ca</Text>
      </View>
      <Image src={uplistLogo} style={{ height: 30 }} />
    </View>
  );
};

const Infosheet = (props) => {
  const { theme } = props;
  return (
    <Document>
      <Page size="letter" style={[styles.page, { color: theme.text.main }]}>
        <TopBar {...props} />
        <Summary {...props} />
        <View
          style={[styles.section, styles.row, styles.section_line]}
          wrap={false}
        >
          <LeadImage {...props} />
          <IconBar {...props} />
        </View>
        <Description {...props} />
        <Details {...props} />
        <AgentInfo {...props} />
        <Footer {...props} />
      </Page>
    </Document>
  );
};

export default Infosheet;
