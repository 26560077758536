import React from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import {
  ContactAgentModal,
  Share,
  StyledCol,
  StyledContainer,
  StyledH1,
  StyledRow,
} from "..";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { useModal } from "../../hooks";

const AgentInfoWrapper = styled(StyledRow)`
  width: ${(p) => (p.theme.isNotSmall ? "80%" : "100%")};
  margin: 0 ${(p) => (p.theme.isNotSmall ? "10%" : "0")};
`;

const OfficeInfoWrapper = styled(StyledRow)`
  margin-top: ${(p) => p.theme.spacing.standard};
`;

const SingleAgentInfo = styled(StyledCol)`
  margin-top: ${(p) => p.theme.spacing.standard};
`;

const AgentName = styled.div`
  font-size: ${(p) => p.theme.fonts.size.header.sm};
  color: ${(p) => p.theme.primary_light.press};
  font-family: ${(p) => p.theme.fonts.visby.bold};
`;

const PREC = styled.div`
  font-size: ${(p) => p.theme.fonts.size.text.sm};
  color: ${(p) => p.theme.text.light};
  font-family: ${(p) => p.theme.fonts.visby.bold};
  text-transform: uppercase;
`;

const ContactInfo = styled.div`
  font-size: ${(p) => p.theme.fonts.size.text.md};
  color: ${(p) => p.theme.text.main};
`;

const OfficeName = styled.div`
  font-size: ${(p) => p.theme.fonts.size.text.md};
  color: ${(p) => p.theme.text.main};
  font-family: ${(p) => p.theme.fonts.visby.bold};
`;

const Image = styled.img`
  max-height: 300px;
  max-width: 100%;
`;

const SizedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const FlexColumn = styled(StyledCol)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SubText = styled.span`
  font-size: ${(p) => p.theme.fonts.size.text.sm};
  color: ${(p) => p.theme.text.light};
  text-transform: capitalize;
  margin-left: 10px;
`;

const DetailsButton = styled(Button)`
  &&& {
    margin-right: 15px;
    margin-top: 15px;
    border-color: ${(p) => p.theme.primary.main};
    &.btn-primary {
      background-color: ${(p) => p.theme.primary.main};
    }
    &.btn-outline-primary {
      color: ${(p) => p.theme.primary_light.press};
      &:active,
      &:focus,
      &:hover {
        color: ${(p) => p.theme.text.white};
      }
    }
    &:active,
    &:focus,
    &:hover {
      background-color: ${(p) => p.theme.primary.hover};
    }
  }
`;

const Link = styled.a`
  color: ${(p) => p.theme.text.main};
  font-size: ${(p) => p.theme.fonts.size.text.md};
  &:hover {
    color: ${(p) => p.theme.text.main};
  }
`;

const ContactLink = ({ link, subtext, type }) => {
  if (!link || !type) return null;
  const protocols = {
    phone: "tel:",
    email: "mailto:",
    web: "https://",
  };
  const href = `${protocols[type]}${link}`;
  return (
    <ContactInfo>
      <Link href={href} target="_blank">
        {link}
      </Link>
      {subtext && <SubText>{subtext}</SubText>}
    </ContactInfo>
  );
};

const PhoneNumbers = ({ phone_numbers }) => {
  return phone_numbers.map((phone_number, i) => {
    const { number, type } = phone_number;
    return <ContactLink key={i} link={number} subtext={type} type="phone" />;
  });
};

const AgentDetails = ({ agent, offices }) => {
  const singleOffice = offices.length === 1;
  return (
    <>
      {!singleOffice && <OfficeName>{agent.office_name}</OfficeName>}
      {agent.phone_numbers.length > 0 && (
        <PhoneNumbers phone_numbers={agent.phone_numbers} />
      )}
      {!singleOffice &&
        agent.display_emails.map(({ email }) => (
          <ContactLink key={email} link={email} type="email" />
        ))}
      <ContactLink link={agent.website} type="web" />
    </>
  );
};

const SingleAgent = (props) => {
  const { agent, singleImage } = props;
  const hide_image = singleImage || agent.hide_image;
  return (
    <>
      {!hide_image && agent?.image_urls && (
        <StyledCol md={3}>
          <SizedImage src={agent.image_urls.large} />
        </StyledCol>
      )}
      <SingleAgentInfo md={hide_image ? 6 : 3}>
        <AgentName>{agent.name}</AgentName>
        {agent.prec && <PREC>Personal Real Estate Corporation</PREC>}
        <AgentDetails {...props} />
      </SingleAgentInfo>
    </>
  );
};

const OfficeInfo = ({ office, emails }) => {
  return (
    <StyledCol>
      <OfficeName>{office.name}</OfficeName>
      <ContactLink link={office.phone_number} type="phone" />
      {emails.map(({ email }) => (
        <ContactLink key={email} link={email} type="email" />
      ))}
    </StyledCol>
  );
};

const SingleAgentTeam = ({ listing }) => {
  const { agents, team, offices, floor_plan_pdfs } = listing;
  const singleImage = team || agents.length === 1;
  const info = team || agents[0];
  const display_emails =
    team?.display_emails || agents.flatMap((a) => a.display_emails);
  const singleOffice = offices.length === 1;
  const hide_image = !singleImage || info.hide_image;
  const { handleModal } = useModal();
  return (
    <AgentInfoWrapper>
      {!hide_image && info?.image_urls && (
        <StyledCol md={4} style={{ textAlign: "center" }}>
          <Image src={info.image_urls.large} />
        </StyledCol>
      )}
      <FlexColumn md={!hide_image ? { span: 7, offset: 1 } : 12}>
        <StyledRow>
          {agents.map((agent, i) => (
            <SingleAgent
              key={i}
              agent={agent}
              offices={offices}
              team={team}
              singleImage={singleImage}
            />
          ))}
        </StyledRow>
        {singleOffice && (
          <OfficeInfoWrapper>
            {offices.map((office, i) => (
              <OfficeInfo key={i} office={office} emails={display_emails} />
            ))}
          </OfficeInfoWrapper>
        )}
        <StyledRow>
          <StyledCol>
            <DetailsButton
              variant="primary"
              onClick={() => handleModal(<ContactAgentModal />)}
            >
              Contact Agent
            </DetailsButton>
            {floor_plan_pdfs?.length > 0 && (
              <DetailsButton
                variant="outline-primary"
                href={`${process.env.REACT_APP_API_URL}/listings/${listing.id}/floorplans.pdf`}
                target="_blank"
              >
                Download Floor Plan
              </DetailsButton>
            )}
            <DetailsButton
              variant="outline-primary"
              onClick={() => handleModal(Share)}
            >
              Share +
            </DetailsButton>
          </StyledCol>
        </StyledRow>
      </FlexColumn>
    </AgentInfoWrapper>
  );
};

const AgentInfo = (props) => {
  const { listing } = useListingState();
  const { agent_names, agents } = listing;
  if (agents.length === 0) return null;

  return (
    <StyledContainer id="agents">
      <StyledRow>
        <StyledCol>
          <StyledH1>Presented By {agent_names}</StyledH1>
        </StyledCol>
      </StyledRow>
      <SingleAgentTeam {...props} listing={listing} />
    </StyledContainer>
  );
};

export default AgentInfo;
