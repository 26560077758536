import { getProductValue } from "../helpers";
import { useWatch } from "react-hook-form";

const useProductsSelected = (skus = [], all = false) => {
  const watch = useWatch({ name: skus });
  return (
    watch.filter(getProductValue).filter(Boolean).length >=
    (all ? skus.length : 1)
  );
};

export default useProductsSelected;
