import React from "react";
import styled from "styled-components";
import { StyledCol, StyledRow, StyledHr } from "../common/Wrappers";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { getInfoItems } from "../../helpers";

const InfoWrapper = styled(StyledRow)`
  color: ${(p) => p.theme.text.main};
  font-family: ${(p) => p.theme.fonts.visby.regular};
`;

const Title = styled.div`
  color: ${(p) => p.theme.text.light};
  font-size: ${(p) => p.theme.fonts.size.text.xs};
  font-family: ${(p) => p.theme.fonts.visby.demibold};
`;

const Item = styled(StyledCol)`
  &&& {
    border-style: solid;
    border-color: ${(p) => p.theme.background.light_border};
    border-width: ${(p) => (p.theme.isLarge ? "0 1px 0 0" : "0 0 1px 0")};
    font-size: ${(p) => p.theme.fonts.size.text[p.theme.isLarge ? "lg" : "md"]};
    ${(p) => (p.theme.isLarge ? "margin: 10px 0;" : "padding: 5px 0;")};
    &:last-of-type {
      border-right: 0;
      border-bottom: 0;
    }
  }
`;

const InfoItem = ({ title, value }) => {
  if (!value) return null;
  return (
    <Item xs={6} lg="auto">
      {title && <Title>{title}</Title>}
      <div>{value}</div>
    </Item>
  );
};

const FullSummary = () => {
  const { listing } = useListingState();
  const info_items = getInfoItems(listing).filter((item) => item.value);
  if (info_items.length < 2) return null;
  return (
    <InfoWrapper>
      <StyledCol lg={6} xl={7} xs={12}>
        <StyledRow>
          {getInfoItems(listing).map((item, i) => (
            <InfoItem key={i} title={item.title} value={item.value} />
          ))}
        </StyledRow>
      </StyledCol>
      <StyledHr />
    </InfoWrapper>
  );
};

export default FullSummary;
