import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useProduct } from "../adapters/uplist";
import { toCurrency } from "../helpers";

export const useSetPriceBySqft = (sku, minimum = 0) => {
  const { data: product } = useProduct(sku);
  const { price } = product || {};
  const { setValue, getValues, watch } = useFormContext();
  const approximate_sqft = watch("order_info.sq_ft");

  const sqft = Math.max(approximate_sqft, minimum);
  const total_price = sqft * price;
  const minimum_price = toCurrency(minimum * price);
  const value = useWatch({ name: sku });
  useEffect(() => {
    const current_value = getValues(sku);
    if (current_value && current_value !== sqft) {
      setValue(sku, sqft);
    }
  }, [value, sqft, sku, setValue, getValues]);

  const price_string = `+${toCurrency(total_price)} ($${price}/sqft ${
    minimum ? "minimum " + minimum_price : ""
  })`;
  return price_string;
};
