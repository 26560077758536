import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";

export const StyledCol = styled(Col)`
  &&& {
    padding: 0 ${(p) => p.theme.spacing.gutter};
`;

export const StyledRow = styled(Row)`
  margin: 0;
`;

const BSContainer = ({ children, className, id }) => (
  <Container className={className} id={id} fluid>
    {children}
  </Container>
);

export const StyledContainer = styled(BSContainer)`
  padding: 0 ${(p) => p.theme.spacing.standard};
`;

export const Hr = styled.hr`
  border-color: ${(p) => p.theme.background.light_border};
  margin: 0 ${(p) => p.theme.spacing.gutter};
`;

export const StyledHr = styled(Hr)`
  width: 100%;
`;

const SectionHr = styled(Hr)`
  margin: ${(p) => p.theme.spacing.wide} ${(p) => p.theme.spacing.gutter};
`;

export const StyledH1 = styled.h1`
  font-size: ${(p) => p.theme.fonts.size.h1};
  font-family: ${(p) => p.theme.fonts.visby.slab_bold};
  color: ${(p) => p.theme.text.light};
  margin-bottom: ${(p) => p.theme.spacing.wide};
  text-align: center;
`;

export const StyledSection = ({ children, id }) => (
  <StyledContainer id={id}>
    {children}
    <SectionHr />
  </StyledContainer>
);
