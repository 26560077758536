import React from "react";
import { Col, Form } from "react-bootstrap";
import { getClasses } from "../../helpers/getClasses";
import AspectRatio from "../order/AspectRatio";
import { useFormContext } from "react-hook-form";

export const ThumbnailCheckbox = ({
  image,
  className,
  selected,
  ratio = 3 / 2,
  overlay_text = "",
}) => {
  const { url, id } = image;

  const { register } = useFormContext();
  return (
    <Col xs={6} md={4}>
      <AspectRatio ratio={ratio} className="mb-4">
        <Form.Group controlId={id}>
          <Form.Check
            type="checkbox"
            className={getClasses([
              className,
              selected && "checked",
              "variant thumbnail",
            ])}
            custom
          >
            <Form.Check.Input
              {...register("stock_photos")}
              type="checkbox"
              value={id}
            />
            <Form.Check.Label
              style={{
                backgroundImage: `url(${url})`,
                backgroundSize: "cover",
              }}
            >
              <div className="overlay">{overlay_text}</div>
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>
      </AspectRatio>
    </Col>
  );
};
