import React from "react";
import { Form } from "react-bootstrap";
import ReactDatePicker from "react-date-picker";
import { Element } from "react-scroll";
import { getClasses } from "../../helpers/getClasses";
import { useController } from "react-hook-form";

export const DatePicker = ({ label, subText, ...props }) => {
  const { field: fieldProps, fieldState } = useController({
    // name: sku,
    // defaultValue,
    // rules: {
    //   validate: async (value, values) => {
    //     if (!validate) return true;
    //     const error = await validate(value, values, props);
    //     if (!error) return true;
    //     return error;
    //   },
    // },
    ...props,
  });
  const { touched, errors, invalid, valid } = fieldState;
  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      //disable weekends
      return [0, 6].includes(date.getDay());
    }
  };
  // const handleChange = (nextValue) => {
  //   handleFieldChange({ nextValue });
  // };
  return (
    <Form.Group as={Element} name={fieldProps.name}>
      {label && (
        <Form.Label className="text-muted">
          {label + (fieldProps.required ? "*" : "")}
        </Form.Label>
      )}
      <ReactDatePicker
        {...fieldProps}
        className={getClasses([
          "form-control",
          valid && "is-valid",
          invalid && "is-invalid",
        ])}
        // onChange={handleChange}
        minDetail="year"
        minDate={new Date()}
        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
        next2Label={null}
        prev2Label={null}
        tileDisabled={tileDisabled}
      />
      {(!touched || !errors) && subText && <Form.Text>{subText}</Form.Text>}
      <Form.Control.Feedback type="invalid">
        {errors && errors.map((error, i) => <div key={i}>{error}</div>)}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

// export default createField({
//   allowMultiple: true,
//   serialize: (value) => {
//     return value.toDateString();
//   },
// })(DatePicker);
