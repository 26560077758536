import React from "react";
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
} from "react-share";
import styled from "styled-components";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { presents } from "../../helpers";

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const Body = () => {
  const { listing } = useListingState();
  const url = `${window.location.origin}${window.location.pathname}`;
  const presents_string = `${listing.agent_names} ${presents(listing)} ${
    listing.address
  }`;
  return (
    <ButtonBar>
      <EmailShareButton subject={presents_string} url={url}>
        <EmailIcon />
      </EmailShareButton>
      <FacebookShareButton url={url} quote={presents_string}>
        <FacebookIcon />
      </FacebookShareButton>
      <TwitterShareButton
        url={url}
        title={presents_string}
        hashtags={["uplist"]}
      >
        <TwitterIcon />
      </TwitterShareButton>
      <PinterestShareButton
        url={url}
        media={listing.photos[0]?.image_urls.large}
        description={presents_string}
      >
        <PinterestIcon />
      </PinterestShareButton>
    </ButtonBar>
  );
};
const Share = { header: "Share this listing!", body: <Body /> };

export default Share;
