import React, { useEffect, useState } from "react";
import {
  StyledCol,
  StyledH1,
  StyledRow,
  StyledSection,
  LinkBar,
  Link,
} from "..";
import ReactPlayer from "react-player/vimeo";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { useStatus } from "../../hooks";

const WidthWrapper = styled.div`
  width: ${(p) => (p.theme.isNotSmall ? "80%" : "100%")};
  margin: 0 ${(p) => (p.theme.isNotSmall ? "10%" : "0")};
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const Player = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
`;

const VideoTitle = styled.span`
  font-family: ${(p) => p.theme.fonts.visby.bold};
  font-size: ${(p) => p.theme.fonts.size.sm};
  color: ${(p) => p.theme.text.light};
`;
const VideoDuration = styled.span`
  font-size: ${(p) => p.theme.fonts.size.sm};
  color: ${(p) => p.theme.text.main};
  float: right;
`;

const Details = styled.div`
  padding: 10px 0;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 80px;
  --fa-primary-color: ${(p) => p.theme.text.white};
  --fa-secondary-color: ${(p) => p.theme.text.main};
  --fa-secondary-opacity: 0.8;
`;

function getVideoName(video) {
  switch (video.name) {
    case "slideshows":
      return "Slideshow";
    case "aerial_video":
      return "Aerial Video";
    case "corkscrew":
      return "Corkscrew";
    default:
      return "Video";
  }
}

const useVimeoMetadata = (vimeo_id) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    axios
      .get(`https://vimeo.com/api/v2/video/${vimeo_id}.json`)
      .then(({ data }) => data?.[0])
      .then((metadata) => {
        if (!metadata) return;
        const { duration, thumbnail_large } = metadata;
        metadata.duration_string = new Date(duration * 1000)
          .toISOString()
          .substring(14, 19);
        metadata.thumbnail_xlarge = thumbnail_large.replace("d_640", "d_1280");
        setData(metadata);
      })
      .finally(() => setLoading(false));
  }, [vimeo_id]);
  return { data, loading };
};

const Video = ({ video }) => {
  const [play, setPlay] = useState(false);
  const { data, loading } = useVimeoMetadata(video.id);

  useStatus({ name: "Videos", loading });
  if (loading) return null;

  return (
    <WidthWrapper>
      <VideoWrapper>
        <Player
          url={`https://vimeo.com/${video.id}`}
          width="100%"
          height="100%"
          light={data.thumbnail_xlarge || true}
          controls={true}
          playing={play}
          onClickPreview={() => setPlay(true)}
          playIcon={<Icon icon={["fad", "play-circle"]} />}
        />
      </VideoWrapper>
      <Details>
        <VideoTitle>{getVideoName(video)}</VideoTitle>
        <VideoDuration>{data.duration_string}</VideoDuration>
      </Details>
    </WidthWrapper>
  );
};

const Videos = () => {
  const [index, setIndex] = useState(0);
  const {
    listing: { videos },
  } = useListingState();
  if (videos.length === 0) return null;

  return (
    <StyledSection id="videos">
      <StyledRow>
        <StyledCol>
          <StyledH1>Videos</StyledH1>
        </StyledCol>
      </StyledRow>
      {videos.length > 1 && (
        <StyledRow>
          <LinkBar>
            {videos.map((video, i) => (
              <Link
                className={i === index ? "active" : ""}
                onClick={() => setIndex(i)}
                key={i}
              >
                {getVideoName(video)}
              </Link>
            ))}
          </LinkBar>
        </StyledRow>
      )}
      <StyledRow>
        <StyledCol>
          <Video video={videos[index]} />
        </StyledCol>
      </StyledRow>
    </StyledSection>
  );
};

export default Videos;
