import React from "react";
import styled from "styled-components";
import useWindowSize from "../../hooks/useWindowSize";
import { StyledRow, StyledCol } from "../common/Wrappers";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AbsoluteGalleryBar = styled(StyledRow)`
  position: absolute;
  top: 40px;
  right: 30px;
  color: ${(p) => p.theme.text.white};
  z-index: 2;
`;
const RowGalleryBar = styled(StyledRow)`
  color: ${(p) => p.theme.text.white};
  background-color: ${(p) => p.theme.accent.main};
  margin-bottom: ${(p) => p.theme.spacing.wide};
`;

const Item = styled(StyledCol)`
  &&& {
    background-color: ${(p) => p.theme.accent.main};
    width: 150px;
    margin-left: ${(p) => (p.theme.isLarge ? "12px" : "0px")};
    text-align: center;
    background-color: ${(p) => p.theme.accent.main};
    font-size: ${(p) => p.theme.fonts.size.text.sm};
    border: 1px solid ${(p) => p.theme.accent.main};
    border-radius: 3px;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    &:hover {
      background-color: ${(p) => p.theme.accent.hover};
    }
  }
`;

const Thumbnail = styled.img`
  height: 120px;
  width: 148px;
  object-fit: cover;
`;
const Title = styled.div`
  padding: 15px 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(p) => (p.theme.isLarge ? "row" : "column")};
  color: ${(p) => p.theme.text.white};
`;

const Text = styled.div`
  line-height: 1;
`;

const Icon = styled(FontAwesomeIcon)`
  margin: ${(p) => (p.theme.isLarge ? "0 5px 0 0" : "0 0 15px 0")};
  font-size: ${(p) => p.theme.fonts.size.text[p.theme.isLarge ? "md" : "lg"]};
`;
const Link = styled.a`
  &:hover {
    text-decoration: none;
  }
`;

const GalleryItem = ({ asset }) => {
  const { isLarge } = useWindowSize();
  return (
    <Item xs lg="auto">
      <Link href={`#${asset.key}`}>
        {isLarge && <Thumbnail src={asset.thumbnail} alt={asset.title} />}
        <Title>
          {asset.icon}
          <Text>{asset.title}</Text>
        </Title>
      </Link>
    </Item>
  );
};

const useGalleryItems = () => {
  const { listing } = useListingState();

  function getThumbnail(key) {
    if (key === "open_houses") return listing[key][0]?.thumbnail;
    if (key === "videos")
      return listing["photos"].find((image) => image.position === 2)?.image_urls
        .small;
    const image_url =
      listing[key].find((image) => image.position === 1)?.image_urls.small ||
      listing[key][0]?.image_urls.small;
    return image_url;
  }
  const default_items = [
    {
      key: "photos",
      title: "Photos",
      icon: <Icon icon={["far", "camera"]} />,
    },
    {
      key: "open_houses",
      title: "Open House",
      icon: <Icon icon={["up", "dollhouse"]} />,
    },
    {
      key: "floor_plans",
      title: "Floor Plan",
      icon: <Icon icon={["up", "floorplan"]} />,
    },
    {
      key: "videos",
      title: "HD Video",
      icon: <Icon icon={["far", "film-alt"]} />,
    },
    {
      key: "virtual_tours",
      title: "360° Tour",
      icon: <Icon icon={["up", "vt"]} />,
    },
  ];
  const assets = default_items
    .filter((item) => listing[item.key] && listing[item.key].length > 0)
    .map((item) => {
      return {
        ...item,
        count: listing[item.key].length,
        thumbnail: getThumbnail(item.key),
      };
    })
    .filter(({ thumbnail }) => thumbnail !== null);
  return assets;
};

const GalleryBar = () => {
  const { isLarge } = useWindowSize();
  const assets = useGalleryItems();

  const Wrapper = isLarge ? AbsoluteGalleryBar : RowGalleryBar;
  return (
    <Wrapper>
      {assets.map((asset, i) => {
        const hide_photos =
          (assets.length > 3 || assets.length === 1) && i === 0;
        const high_index = i > (assets.length > 3 ? 3 : 2);
        if (hide_photos || high_index) return null;
        return <GalleryItem key={asset.key} asset={asset} />;
      })}
    </Wrapper>
  );
};

export default GalleryBar;
