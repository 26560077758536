import React, { lazy } from "react";
import { NotFound } from "./components";
import "./helpers/fontlibrary";

const Listing = lazy(() => import("./Listing"));
const Order = lazy(() => import("./Order"));
const App = () => {
  const subdomain = window.location.host.split(".")[0];
  switch (subdomain) {
    case "listing":
    case "listing-staging":
      return <Listing />;
    case "order":
    case "order-staging":
      return <Order />;
    case "order-internal":
    case "order-internal-staging":
      sessionStorage.setItem("internal", true);
      return <Order />;
    default:
      return <NotFound />;
  }
};

export default App;
