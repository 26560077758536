import FP from "./FP.png";
import FP_F from "./FP_F.png";
import FP_FM from "./FP_FM.png";
import FP_FM_F from "./FP_FM_F.png";
import FP_FM_GM from "./FP_FM_GM.png";
import FP_FM_GM_F from "./FP_FM_GM_F.png";
import FP_FM_GR from "./FP_FM_GR.png";
import FP_FM_GR_F from "./FP_FM_GR_F.png";
import FP_FR from "./FP_FR.png";
import FP_FR_F from "./FP_FR_F.png";
import FP_FR_GM from "./FP_FR_GM.png";
import FP_FR_GM_F from "./FP_FR_GM_F.png";
import FP_FR_GR from "./FP_FR_GR.png";
import FP_FR_GR_F from "./FP_FR_GR_F.png";
import FP_GM from "./FP_GM.png";
import FP_GM_F from "./FP_GM_F.png";
import FP_GR from "./FP_GR.png";
import FP_GR_F from "./FP_GR_F.png";

const PREMIUM_FP_ADDON_IMAGES = {
  FP,
  FP_F,
  FP_FM,
  FP_FM_F,
  FP_FM_GM,
  FP_FM_GM_F,
  FP_FM_GR,
  FP_FM_GR_F,
  FP_FR,
  FP_FR_F,
  FP_FR_GM,
  FP_FR_GM_F,
  FP_FR_GR,
  FP_FR_GR_F,
  FP_GM,
  FP_GM_F,
  FP_GR,
  FP_GR_F,
};

export default PREMIUM_FP_ADDON_IMAGES;
