import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Element } from "react-scroll";
import { getClasses } from "../../helpers/getClasses";
import { useProductInput } from "../../hooks";
import { isInternal } from "../../helpers";
import InfoIcon from "../order/InfoIcon";

const ShowPasswordButton = ({ visible, setVisible }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip>{`${visible ? "Hide" : "Show"} Password`}</Tooltip>}
      delay={{ show: 400, hide: 0 }}
    >
      <Icon
        icon={["far", visible ? "eye-slash" : "eye"]}
        onClick={() => setVisible((visible) => !visible)}
      />
    </OverlayTrigger>
  );
};

const PasswordWrapper = ({ password, type, fieldState, children }) => {
  const { isTouched } = fieldState;
  const [visible, setVisible] = useState(false);
  if (!password) return children(type);
  return (
    <div className={`pass-wrapper ${isTouched ? "validated" : ""}`}>
      {children(visible ? "text" : "password")}
      <ShowPasswordButton visible={visible} setVisible={setVisible} />
    </div>
  );
};

export const Input = (props) => {
  const { hidden, discount, modal, ...options } = props;
  const { fieldProps, fieldState, productFields } = useProductInput({
    type: "text",
    defaultValue: "",
    ...options,
  });
  const { password, asyncRule, ...rest } = fieldProps;
  const { label, price, description } = productFields;
  const { error, invalid, valid } = fieldState;

  return (
    <Form.Group
      as={Element}
      name={fieldProps.name}
      className={getClasses([hidden && "d-none"])}
    >
      {label && (
        <Form.Label className="text-muted">
          <span>{label}</span>
          {price && (
            <>
              {" "}
              <span className="price">{price}</span>{" "}
              {discount && <span className="discount">{discount}</span>}
              {fieldProps.type === "number" && "Each"}
            </>
          )}
        </Form.Label>
      )}
      {!isInternal() && modal && <InfoIcon modal={modal} />}
      <PasswordWrapper
        password={password}
        type={fieldProps.type}
        fieldState={fieldState}
      >
        {(type) => (
          <>
            <Form.Control
              {...rest}
              isValid={valid}
              isInvalid={invalid}
              type={type}
            />
            {!error && description && <Form.Text>{description}</Form.Text>}
            <Form.Control.Feedback type="invalid">
              {error && <div>{error.message}</div>}
            </Form.Control.Feedback>
          </>
        )}
      </PasswordWrapper>
    </Form.Group>
  );
};
