import axios from "axios";
import { getToken, isInternal, setToken } from "../helpers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useFormContext, useWatch } from "react-hook-form";
import { useDebounce, useReadLocalStorage } from "usehooks-ts";
import useFullAddress from "../hooks/useFullAddress";
import { useEffect } from "react";

export const uplist = axios.create({
  baseURL: (process.env.REACT_APP_API_URL || "https://uplist.ca") + "/api",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json;charset=UTF-8",
  },
});
uplist.interceptors.request.use(
  (config) => {
    const token = getToken();
    config.headers["Authorization"] = token ? `Token ${token}` : token;
    return config;
  },
  (error) => Promise.reject(error)
);
uplist.interceptors.response.use(
  (response) => {
    if (response.headers["refresh-token"]) {
      setToken(response.headers["refresh-token"]);
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export const fetchUplist = (path, params) =>
  uplist.get(path, { params }).then(({ data }) => data);

export const postUplist = (path, data) =>
  uplist.post(path, data).then(({ data }) => data);

export const useUser = (providedId, options) => {
  const id = isInternal() ? providedId : "current";
  const token = useReadLocalStorage("token");
  return useQuery(
    ["user", id, token],
    () => fetchUplist(`/users/${id}`).then((data) => data?.user),
    {
      enabled: !!id && (isInternal() || !!token),
      ...options,
    }
  );
};

export const useUpdateUserPrintTemplates = (options) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, sku }) => {
      return postUplist(`/users/${id}/add_print_template`, { sku });
    },
    onSuccess: (_res, { id }) => {
      queryClient.invalidateQueries(["user", id]);
    },
    ...options,
  });
};

export const useProducts = (options) => {
  const params = { internal: isInternal() };
  return useQuery(
    ["products", params],
    () => fetchUplist("/products", params),
    options
  );
};

export const useProduct = (id) => {
  const queryClient = useQueryClient();
  const params = { internal: isInternal() };
  return useQuery(
    ["products", params, id],
    () => fetchUplist("/products", params),
    {
      initialData: () => {
        const data = queryClient.getQueryData(["products", params]);
        return id && data ? data[id] : undefined;
      },
      enabled: !!id,
    }
  );
};

export const useTravelZones = (options) =>
  useQuery(
    ["travel_zones"],
    () => fetchUplist("/products/travel_charge_zones"),
    options
  );

export const useMLSResults = (options) => {
  const [street_number, unit_number, street_name] = useWatch({
    name: [
      "address_info.street_number",
      "address_info.unit_number",
      "address_info.street_name",
    ],
  });
  const params = { street_name, street_number, unit_number };
  const dbParams = useDebounce(params, 500);
  return useQuery(
    ["mls_results", dbParams],
    () => fetchUplist("/search_mls", dbParams),
    {
      enabled: !!(
        dbParams.street_name &&
        dbParams.street_number &&
        isInternal()
      ),
      ...options,
    }
  );
};

export const useStockRegions = (options) => {
  const latlng = useWatch({ name: "address_info.latlng" });
  const params = { location: latlng?.toJSON ? latlng.toJSON() : latlng };
  return useQuery(
    ["stock_regions", params],
    () => fetchUplist("/assets/stock_photos", params),
    { enabled: !!latlng && !isInternal(), ...options }
  );
};

export const usePrefetchCatalogFloorplan = () => {
  const queryClient = useQueryClient();
  const { full_address } = useFullAddress();

  useEffect(() => {
    if (full_address) {
      queryClient.prefetchQuery({
        queryKey: ["catalog_floorplan", full_address],
        queryFn: () =>
          fetchUplist("/assets/catalog_floorplan", { address: full_address }),
      });
    }
  }, [queryClient, full_address]);
};

export const useCatalogFloorplan = (options) => {
  const { full_address } = useFullAddress();
  return useQuery(
    ["catalog_floorplan", full_address],
    () => fetchUplist("/assets/catalog_floorplan", { address: full_address }),
    { enabled: !!full_address, ...options }
  );
};

export const useOffices = (options) => {
  return useQuery(["offices"], () => fetchUplist("/offices"), {
    staleTime: Infinity,
    ...options,
  });
};

export const useLoginUser = () => {
  const { setError } = useFormContext();
  return useMutation({
    mutationFn: (data) => postUplist("/users/sign_in", data),
    onSuccess: (data) => setToken(data?.token),
    onError: ({ response: { data } }) => {
      setError(
        "password",
        { type: "validate", message: data || "Something went wrong" },
        { shouldFocus: true }
      );
    },
  });
};

export const useUsers = (options) => {
  return useQuery(["users"], () => fetchUplist("/users"), {
    enabled: isInternal(),
    ...options,
  });
};

export const useBundles = (options) => {
  return useQuery(["bundles"], () => fetchUplist("/products/bundles"), options);
};

export const useResetPassword = (options) => {
  return useMutation({
    mutationFn: (data) => {
      return postUplist("/users/forgot_password", data);
    },
    ...options,
  });
};
export default uplist;
