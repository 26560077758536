import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useStateContext as useListingState } from "../../context/ListingContext";
import { presents } from "../../helpers/presents";

const Address = styled.div`
  font-family: ${(p) => p.theme.fonts.visby.slab_demibold};
  font-size: ${(p) => p.styling.fontSize};
  color: ${(p) => p.styling.color};
  line-height: ${(p) => p.styling.fontSize};
  max-width: ${(p) => p.styling.maxWidth};
`;
const Agent = styled.span`
  color: ${(p) => p.theme.accent.main};
  font-size: ${(p) => p.styling.fontSize};
  font-family: ${(p) => p.theme.fonts.visby.bold};
  line-height: ${(p) => p.styling.fontSize};
`;
const Presents = styled.span`
  font-family: ${(p) => p.theme.fonts.visby.medium};
`;

const TitleWrapper = styled.div`
  background-color: ${(p) => p.styling.backgroundColor};
  border-bottom: solid 1px ${(p) => p.styling.borderColor};
  padding: ${(p) => p.styling.padding};
`;

const useStyling = (light) => {
  const theme = useContext(ThemeContext);
  const styling = {
    agent: {
      fontSize: theme.fonts.size[light ? "header" : "text"].sm,
    },
    address: {
      fontSize: light ? theme.fonts.size.header.xl : theme.fonts.size.text.sm,
      color: light ? theme.text.dark : theme.text.white,
      maxWidth: light ? "50%" : "100%",
    },
    wrapper: {
      backgroundColor: light ? theme.background.light : theme.background.dark,
      borderColor: light
        ? theme.background.light_border
        : theme.background.dark,
      padding: light ? `22px ${theme.spacing.wide}` : "0",
    },
  };
  return styling;
};
const TitleBar = ({ light = true }) => {
  const { listing } = useListingState();
  const { agent, address, wrapper } = useStyling(light);
  return (
    <TitleWrapper
      styling={wrapper}
      className={light ? "d-none d-lg-block" : "d-lg-none"}
    >
      <Agent styling={agent}>
        <span className="mr-1">{listing.agent_names}</span>
        <Presents>{presents(listing)}</Presents>
      </Agent>
      <Address styling={address}>
        {!listing.hide_address ? listing.address : " "}
      </Address>
    </TitleWrapper>
  );
};

export default TitleBar;
