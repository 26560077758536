import { Spinner } from "react-bootstrap";

export const Loading = () => {
  return (
    <div id="loadingScreen">
      <Spinner
        animation="border"
        variant="primary"
        style={{ width: 75, height: 75 }}
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
};
