import React from "react";
import { Nav } from "react-bootstrap";
import styled from "styled-components";
import {
  StyledCol,
  StyledContainer,
  StyledRow,
  SmNavLink,
  Terms,
  Privacy,
} from "..";
import uplistLogo from "../../assets/images/uplist-logo-white.png";
import realfotoLogo from "../../assets/images/realfoto-logo-white.png";
import { useModal } from "../../hooks";

const FooterContainer = styled(StyledContainer)`
  margin-top: ${(p) => p.theme.spacing.wide};
  background-color: ${(p) => p.theme.background.dark};
  padding-top: ${(p) => p.theme.spacing.wide};
  padding-bottom: 80px;
  min-height: 50px;
`;

const Hr = styled.hr`
  border-color: ${(p) => p.theme.text.main};
  margin: ${(p) => p.theme.spacing.wide} 0;
`;

const Logo = styled.img`
  height: 35px;
  margin: ${(p) => (p.theme.isNotSmall ? 0 : p.theme.spacing.standard)} 0;
  &.uplist-logo {
    margin-right: ${(p) => p.theme.spacing.wide};
  }
`;

const Copyright = styled.p`
  color: ${(p) => p.theme.text.light};
  margin: 0;
`;

const Links = styled(Nav)`
  float: ${(p) => (p.theme.isNotSmall ? "right" : "left")};
  font-size: ${(p) => p.theme.fonts.size.text.sm};
  font-family: ${(p) => p.theme.fonts.visby.bold};
  color: ${(p) => p.theme.text.white};
  margin-top: ${(p) => (p.theme.isNotSmall ? 0 : p.theme.spacing.standard)};
`;

const Link = styled.a`
  margin-left: 5px;
  color: ${(p) => p.theme.text.white};
  &:hover {
    color: ${(p) => p.theme.text.white};
  }
`;

const NavLink = styled(SmNavLink)`
  &&& {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;

const LogoLink = styled.a`
  display: inline-block;
  float: ${(p) => (p.theme.isNotSmall ? "none" : "right")};
`;

const Footer = () => {
  const { handleModal } = useModal();
  return (
    <FooterContainer>
      <StyledRow>
        <StyledCol>
          <Logo src={uplistLogo} alt="Logo" className="uplist-logo" />
          <LogoLink href="https://realfoto.ca" target="_blank" rel="noreferrer">
            <Logo src={realfotoLogo} alt="Logo" />
          </LogoLink>
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          <Hr />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol md={6}>
          <Copyright>
            {`Uplist © ${new Date().getFullYear()}. `}
            <span>Content provided by</span>
            <Link
              variant="link"
              href="https://realfoto.ca"
              target="_blank"
              rel="noreferrer"
            >
              Realfoto
            </Link>
            <span>. Made by</span>
            <Link
              variant="link"
              href="https://thenumber.ca"
              target="_blank"
              rel="noreferrer"
            >
              The Number.
            </Link>
          </Copyright>
          <Copyright>
            All images belong to their respective owners/creators.
          </Copyright>
        </StyledCol>
        <StyledCol md={6}>
          <Links>
            <NavLink onClick={() => handleModal(Terms, { size: "xl" })}>
              TERMS OF USE
            </NavLink>
            <NavLink onClick={() => handleModal(Privacy, { size: "xl" })}>
              PRIVACY POLICY
            </NavLink>
          </Links>
        </StyledCol>
      </StyledRow>
    </FooterContainer>
  );
};

export default Footer;
