import React, { cloneElement, useState } from "react";
import { Button, Modal as BootstrapModal } from "react-bootstrap";
import styled from "styled-components";
import { useModalContext } from "../../context/ModalContext";

const ModalHeader = styled(BootstrapModal.Header)`
  justify-content: center;
  font-family: ${(p) => p.theme.fonts.visby.bold};
  font-size: ${(p) => p.theme.fonts.size.header.sm};
  .close {
    font-size: 2rem;
  }
`;

const CloseButton = styled(Button)`
  &&& {
    border-color: ${(p) => p.theme.primary.main};
    &:active,
    &:focus,
    &:hover {
      border-color: ${(p) => p.theme.primary.hover};
    }
  }
`;

const ModalContent = ({ content, handleClose, entered }) => {
  // Overwrite content structure by passing a component instead of object
  if (React.isValidElement(content))
    return cloneElement(content, { handleClose, entered });
  const { header, body, footer } = content;
  return (
    <>
      <ModalHeader closeButton>{header}</ModalHeader>
      <BootstrapModal.Body>{body}</BootstrapModal.Body>
      <BootstrapModal.Footer>
        <CloseButton variant="outline" onClick={handleClose}>
          Close
        </CloseButton>
        {footer}
      </BootstrapModal.Footer>
    </>
  );
};

const Modal = () => {
  const { visible, content, options, setVisible } = useModalContext();
  const [entered, setEntered] = useState(false);
  const handleClose = () => {
    setVisible(false);
  };
  if (!content) return null;
  return (
    <BootstrapModal
      show={visible}
      onHide={handleClose}
      onEntered={() => setEntered(true)}
      onExiting={() => setEntered(false)}
      {...options}
    >
      <ModalContent
        content={content}
        handleClose={handleClose}
        entered={entered}
      />
    </BootstrapModal>
  );
};

export default Modal;
