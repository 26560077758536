import React, { useEffect, useMemo, useState } from "react";
import { useStateContext as useListingState } from "../../context/ListingContext";
import {
  StyledCol,
  StyledH1,
  StyledRow,
  StyledSection,
  LinkBar,
  Link,
} from "..";
import ReactPannellum, { addScene, loadScene } from "react-pannellum";
import useMaxHeight from "../../hooks/useMaxHeight";
import styled from "styled-components";

const Tour = styled(ReactPannellum)`
  height: ${(p) => p.maxHeight}px !important;
  width: ${(p) => (p.theme.isNotSmall ? "80%" : "100%")} !important;
  margin: 0 ${(p) => (p.theme.isNotSmall ? "10%" : "0")};
  .pnlm-panorama-info {
    display: none !important;
  }
`;

const VirtualTour = () => {
  const [index, setIndex] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const { listing } = useListingState();
  const maxHeight = useMaxHeight([200, 600]);
  const virtual_tours = listing.virtual_tours.sort(
    (a, b) => a.position - b.position
  );

  const config = useMemo(() => {
    return {
      autoLoad: true,
      minPitch: -135,
      maxPitch: 135,
      autoRotate: 5,
      autoRotateInactivityDelay: 30000,
      showZoomCtrl: false,
      mouseZoom: false,
      sceneFadeDuration: 1000,
    };
  }, []);

  useEffect(() => {
    if (loaded && virtual_tours.length > 1) {
      virtual_tours.forEach((vt, i) => {
        if (i === 0) return;
        addScene(vt.title, {
          ...config,
          imageSource: vt.image_urls.original,
        });
      });
    }
  }, [virtual_tours, loaded, config]);
  if (virtual_tours.length === 0) return null;
  const initial_tour = virtual_tours[0];

  const setScene = (vt, i) => {
    loadScene(vt.title);
    setIndex(i);
  };
  return (
    <StyledSection id="virtual_tours">
      <StyledRow>
        <StyledCol xs={12}>
          <StyledH1>Virtual Tours</StyledH1>
        </StyledCol>
      </StyledRow>
      {virtual_tours.length > 1 && (
        <StyledRow>
          <LinkBar>
            {virtual_tours.map((vt, i) => (
              <Link
                className={i === index ? "active" : ""}
                onClick={() => setScene(vt, i)}
                key={i}
              >
                {vt.title}
              </Link>
            ))}
          </LinkBar>
        </StyledRow>
      )}
      <StyledRow>
        <StyledCol xs={12}>
          <Tour
            id="PannellumTour"
            sceneId={initial_tour.title}
            imageSource={initial_tour.image_urls.original}
            config={config}
            maxHeight={maxHeight}
            onPanoramaLoaded={(e) => setLoaded(true)}
          />
        </StyledCol>
      </StyledRow>
    </StyledSection>
  );
};

export default VirtualTour;
