import styled from "styled-components";

const Button = styled.button`
  border-radius: 8px;
  padding: 10px;
  border: 1px solid;
  color: ${(p) => p.theme.primary.text};
  background-color: ${(p) => p.theme.primary.main};
  border-color: ${(p) => p.theme.primary.main};
`;

export const PrimaryButton = styled(Button)`
  &:hover {
    background-color: ${(p) => p.theme.primary.hover};
  }
  &:active {
    background-color: ${(p) => p.theme.primary.press};
  }
`;

export const OutlineButton = styled(Button)`
  background-color: transparent;
  color: ${(p) => p.theme.primary_light.main};
  &:hover {
    background-color: ${(p) => p.theme.primary.main};
    color: ${(p) => p.theme.primary.text};
  }
`;
