import React from "react";

const Body = () => (
  <>
    <p>
      Any use of the website uplist.ca constitutes an agreement to be bound by
      our terms and conditions of use, which together with our privacy policy
      govern Realfoto Media Inc.'s relationship with you in relation to this
      website. If you disagree with any part of these terms and conditions,
      please do not use our website.
    </p>
    <p>The use of this website is subject to the following terms of use:</p>
    <ul>
      <li>
        The information contained in this website is for general information
        purposes only, and is subject to change without notice. While we attempt
        to keep our website information up to date and correct, we make no
        representations or warranties of any kind, express or implied, about
        completeness, accuracy, reliability, suitability or availability with
        respect to the website or the information, products, services, or
        related graphics contained on the website.
      </li>
      <li>
        This website may use cookies to monitor browsing preferences. If you do
        allow cookies to be used, personal information may be stored by us for
        use by third parties.
      </li>
      <li>
        Neither Realfoto Media Inc. nor any third parties provide any warranty
        or guarantee as to the accuracy, timeliness, performance, completeness
        or suitability of the information and materials found or offered on this
        website for any particular purpose. The use of any information or
        materials on this website is entirely at the user’s own risk, for which
        Realfoto Media Inc. shall not be liable. It is the user’s responsibility
        to ensure that any products, services or information available through
        this website meet the user’s specific requirements. In no event will
        Realfoto Media Inc. or any third party be liable for any loss or damage
        including without limitation, indirect or consequential loss or damage,
        or any loss or damage whatsoever arising from loss of data or profits
        arising out of, or in connection with, the use of this website.
      </li>
      <li>
        This website contains material which is owned by or licensed to Realfoto
        Media Inc. This material may includes, but is not limited to, design,
        layout, look, appearance and graphics. Reproduction is prohibited other
        than in accordance with the copyright notice posted on this website, and
        which forms part of these terms and conditions.
      </li>
      <li>
        All trademarks reproduced in this website which are not the property of,
        or licensed to, the operator are acknowledged on the website.
      </li>
      <li>
        Through this website you may be able to link to other websites which are
        not under the control of Realfoto Media Inc. The inclusion of any links
        does not necessarily imply a recommendation or endorse the views
        expressed within them.
      </li>
      <li>
        Realfoto Media Inc. takes no responsibility for, and will not be liable
        for, this website being temporarily unavailable due to technical issues
        beyond our control.
      </li>
      <li>
        Any use of this website and any dispute arising out of such use is
        subject to the governing laws of Canada.
      </li>
    </ul>
  </>
);

const Terms = { header: "Terms of Use", body: <Body /> };

export default Terms;
