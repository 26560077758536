import { flatMap, forEach, map, pickBy } from "lodash";
import { useEffect } from "react";
import { Image as BSImage, Col, Row } from "react-bootstrap";
import { useFormContext, useWatch } from "react-hook-form";
import { useProduct } from "../../../adapters/uplist";
import { VIRTUAL_STAGING_IMAGES } from "../../../assets/images/samples";
import { useFormDetailsState } from "../../../context/FormDetailsContext";
import { toCurrency } from "../../../helpers";
import { VIRTUAL_STAGING_STYLES } from "../../../helpers/constants";
import { useModal } from "../../../hooks";
import { Button, InlineCheckbox, Input, Notes } from "../../fields";
import AddOnSection from "../AddOnSection";
import CarouselModal from "../CarouselModal";

const VIRTUAL_STAGING_SKUS = flatMap(VIRTUAL_STAGING_STYLES, (s) => [
  s.single,
  s.bundle,
]);

export const STYLE_NAMES = Object.keys(VIRTUAL_STAGING_STYLES);

const useSelectedStyle = () => {
  const values = useWatch();
  const selected_name = pickBy(values, (v, k) => STYLE_NAMES.includes(k) && v);
  const name = Object.keys(selected_name)[0];
  const index = STYLE_NAMES.indexOf(name);
  return { ...VIRTUAL_STAGING_STYLES[name], name, index };
};

export const VirtualStaging = () => {
  const { data: single } = useProduct("1320");
  const { data: pack } = useProduct("1321");

  const staging_price_string = `+${toCurrency(
    single.price
  )}/each or ${toCurrency(pack.price)}/3-pack`;

  useEffect(() => {
    // preload images to prevent image loading when using style selector
    forEach(VIRTUAL_STAGING_IMAGES, (value) => {
      new Image().src = value;
    });
  }, []);

  return (
    <div className="mb-3">
      <InlineCheckbox
        sku="virtual_staging"
        label="Virtual Staging"
        description={
          "Show the potential of a vacant space with realistically rendered decor. Choose from seven stylish themes. Rooms must be completely vacant; next day turnaround."
        }
        custom_price={staging_price_string}
        labelClassName="h2 text-black"
        isNew
        modal={{ urls: map(VIRTUAL_STAGING_IMAGES, (v) => v) }}
      />
      <AddOnSection
        show_skus={["virtual_staging"]}
        addon_skus={[...VIRTUAL_STAGING_SKUS, ...STYLE_NAMES]}
        hideInternal
      >
        <div className="mb-2 text-muted">Choose Your Theme*</div>

        <Row>
          <Col xs={12} sm={4} className="mb-4">
            {STYLE_NAMES.map((name, index) => {
              return (
                <Button
                  key={name}
                  sku={name}
                  label={name}
                  defaultValue={index === 0}
                />
              );
            })}
          </Col>
          <StagingImages />
        </Row>
        <StagingQuantity />
        <div className="mb-5">
          <Notes
            sku="notes.virtual_staging"
            label="Rooms to Stage and Notes"
            rows={3}
            placeholder="Example: Living Room, Kitchen, Primary Bedroom. Please put couch opposite fireplace."
            required
          />
        </div>
      </AddOnSection>
    </div>
  );
};

const StagingQuantity = () => {
  const { setValue } = useFormContext();

  const selected_style = useSelectedStyle();

  const selected_single_sku = selected_style?.single;
  const selected_bundle_sku = selected_style?.bundle;
  const handleChange = (e, value) => {
    const bundle_count = Math.floor(value / 3);
    const single_count = value % 3;
    STYLE_NAMES.forEach((key) => {
      const { single, bundle } = VIRTUAL_STAGING_STYLES[key];
      setValue(single, selected_single_sku === single ? single_count : 0);
      setValue(bundle, selected_bundle_sku === bundle ? bundle_count : 0);
    });
  };

  return (
    <Input
      type="number"
      name="virtual_staging_qty"
      label="Quantity"
      onChange={handleChange}
      placeholder="How many photos would you like staged?"
      required
    />
  );
};

const StagingImages = () => {
  const { handleModal } = useModal();
  const selected_style = useSelectedStyle();
  const {
    footerHeight: { order_form },
  } = useFormDetailsState();
  return (
    <Col
      xs={12}
      sm={8}
      onClick={(e) => {
        e.preventDefault();
        handleModal(
          <CarouselModal
            urls={map(VIRTUAL_STAGING_IMAGES, (v) => v)}
            initialIndex={selected_style.index}
          />,
          { dialogClassName: "sample-modal", centered: true }
        );
      }}
    >
      {STYLE_NAMES.map((key, index) => {
        return (
          <BSImage
            key={key}
            src={VIRTUAL_STAGING_IMAGES[key]}
            style={{
              maxHeight: `calc(100vh - 3rem - ${order_form}px)`,
              maxWidth: "100%",
              position: "sticky",
              top: 0,
              display: key === selected_style?.name ? "inline" : "none",
            }}
          />
        );
      })}
    </Col>
  );
};
