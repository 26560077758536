import React from "react";
import { Form } from "react-bootstrap";
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";
import { css } from "styled-components";

import { getClasses } from "../../helpers/getClasses";
import { useProductInput } from "../../hooks";
import { useFormContext } from "react-hook-form";

const cardNumberValidator = ({ cardType }) => {
  if (
    cardType.displayName === "Visa" ||
    cardType.displayName === "Mastercard"
  ) {
    return;
  }
  return "Card must be Visa or Mastercard";
};

export const CreditCard = () => {
  const {
    setValue,
    formState: { isValid },
  } = useFormContext();

  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    meta: { erroredInputs, cardType },
  } = usePaymentInputs({
    cardNumberValidator,
    onChange: () => setValue("cardType", cardType?.type),
  });

  const { error: ccError, isTouched } = wrapperProps;

  const validate = (props) => erroredInputs[props.name];

  const {
    fieldProps: cvcProps,
    fieldState: { error: cvcError },
  } = useProductInput({
    required: true,
    validate,
    shouldUnregister: true,
    defaultValue: "",
    ...getCVCProps({}),
  });
  const {
    fieldProps: cardNumberProps,
    fieldState: { error: cardNumberError },
  } = useProductInput({
    required: true,
    validate,
    shouldUnregister: true,
    defaultValue: "",
    ...getCardNumberProps({}),
  });
  const {
    fieldProps: expiryProps,
    fieldState: { error: expiryError },
  } = useProductInput({
    required: true,
    validate,
    shouldUnregister: true,
    defaultValue: "",
    ...getExpiryDateProps({}),
  });

  const cardTypeInput = useProductInput({
    shouldUnregister: true,
    defaultValue: "",
    name: "cardType",
    type: "hidden",
  });
  const formError =
    cvcError?.message || cardNumberError?.message || expiryError?.message;
  const error = (isTouched && ccError) || formError;
  return (
    <>
      <h2>One More Thing</h2>
      <p>
        Please provide a credit card that we can keep on file for all future
        orders. Once your appointment has been completed and all products
        delivered we will charge the card provided and send you a receipt.
      </p>
      <input {...cardTypeInput.fieldProps} />
      <Form.Group>
        <Form.Label className="text-muted">Credit Card*</Form.Label>
        <PaymentInputsWrapper
          {...wrapperProps}
          error={error}
          isTouched={isTouched || !isValid}
          styles={{
            inputWrapper: {
              focused: css`
                border-color: unset;
                box-shadow: unset;
              `,
              errored: css`
                box-shadow: unset;
              `,
            },
          }}
          className="credit-card"
          inputWrapperProps={{
            className: getClasses([
              "form-control",
              error && "is-invalid",
              !error && isTouched && "is-valid",
            ]),
          }}
          errorTextProps={{
            className: "invalid-feedback",
          }}
        >
          <svg {...getCardImageProps({ images })} />
          <input {...cardNumberProps} />
          <input {...expiryProps} />
          <input {...cvcProps} />
        </PaymentInputsWrapper>
      </Form.Group>
    </>
  );
};
