import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useCatalogFloorplan, useProduct } from "../adapters/uplist";

export const useFpProduct = () => {
  const { data: sqftProduct } = useProduct("2002");
  const { setValue, getValues } = useFormContext();
  const { data: catalogFp } = useCatalogFloorplan();

  const [sqft = 0, add_sqft = 0, fpSku] = useWatch({
    name: ["order_info.sq_ft", "order_info.add_sq_ft", "fpSku"],
  });

  const sqftPrice = sqftProduct?.price;

  const total_sqft = !!fpSku && fpSku !== "2501" ? sqft + add_sqft : 0;
  useEffect(() => {
    setValue("sqftCharge", total_sqft * sqftPrice);
  }, [setValue, total_sqft, sqftPrice]);

  useEffect(() => {
    if (!catalogFp?.match_found) setValue("fpSku", "2001");
  }, [catalogFp?.match_found, setValue]);

  const fpSelected = !!fpSku && getValues(fpSku);

  useEffect(() => {
    setValue("2002", (fpSelected && total_sqft) || undefined);
  }, [fpSelected, total_sqft, setValue]);
  return { smallCondo: false };
};
