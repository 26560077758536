import React from "react";
import { Input } from "./Input";

export const HiddenProduct = (props) => {
  return (
    <div style={{ display: "none" }}>
      <Input {...props} type="hidden" />
    </div>
  );
};
