import React from "react";
import styled from "styled-components";
const StyledHeader = styled.h3`
  font-family: ${(p) => p.theme.fonts.visby.slab_demibold};
  font-size: ${(p) => p.theme.fonts.size.header.sm};
`;

const Body = () => (
  <>
    <p>
      If you choose to use our Service, then you agree to the collection and use
      of information in relation to this policy. The Personal Information that
      we collect is used for providing and improving the Service. We will not
      use or share your information with anyone except as described in this
      Privacy Policy.
    </p>
    <StyledHeader>Information Collection and Use</StyledHeader>
    <p>
      While using our Service, we may require you to provide us with certain
      personally identifiable information, including but not limited to name,
      email address, agent ID, brokerage information, phone number, payment
      information, and listing information. The information that we request will
      be retained by us and used as described in this privacy policy.
    </p>
    <p>
      We use your information to contact you, link your listings to the MLS
      service and other services you are subscribed to, deliver your content,
      take payment, and enable prospective buyers to contact you through
      Uplist.ca.
    </p>
    <p>
      We do not disclose your personal or listing information to any other
      clients or non-relevant parties.
    </p>
    <p>
      Our service does use third party services that may collect information
      used to identify you.
    </p>
    <StyledHeader>Cookies</StyledHeader>
    <p>
      Cookies are files with small amount of data that is commonly used an
      anonymous unique identifier. These are sent to your browser from the
      website that you visit and are stored on your device internal memory.
    </p>
    <p>
      This Service does not use these “cookies” explicitly. However, the app may
      use third party code and libraries that use “cookies” to collection
      information and to improve their services. You have the option to either
      accept or refuse these cookies and know when a cookie is being sent to
      your device. If you choose to refuse our cookies, you may not be able to
      use some portions of this Service.
    </p>
    <StyledHeader>Service Providers</StyledHeader>
    <p>
      We may employ third-party companies and individuals due to the following
      reasons:
    </p>
    <ul>
      <li>To facilitate our Service;</li>
      <li>To provide the Service on our behalf;</li>
      <li>To perform Service-related services; or</li>
      <li>To assist us in analyzing how our Service is used.</li>
    </ul>
    <p>
      We want to inform users of this Service that these third parties have
      access to your Personal Information. The reason is to perform the tasks
      assigned to them on our behalf. However, they are obligated not to
      disclose or use the information for any other purpose.
    </p>
    <StyledHeader>Security</StyledHeader>
    <p>
      We value your trust in providing us your Personal Information, thus we are
      striving to use commercially acceptable means of protecting it. But
      remember that no method of transmission over the internet, or method of
      electronic storage is 100% secure and reliable, and we cannot guarantee
      its absolute security.
    </p>
    <StyledHeader>Links to Other Sites</StyledHeader>
    <p>
      This Service may contain links to other sites. If you click on a
      third-party link, you will be directed to that site. Note that these
      external sites are not operated by us. Therefore, we strongly advise you
      to review the Privacy Policy of these websites. We have no control over
      and assume no responsibility for the content, privacy policies, or
      practices of any third-party sites or services.
    </p>
    <StyledHeader>Children’s Privacy</StyledHeader>
    <p>
      These Services do not address anyone under the age of 13. We do not
      knowingly collect personally identifiable information from children under
      13. In the case we discover that a child under 13 has provided us with
      personal information, we immediately delete this from our servers. If you
      are a parent or guardian and you are aware that your child has provided us
      with personal information, please contact us so that we will be able to do
      necessary actions.
    </p>
  </>
);

const Privacy = { header: "Privacy Policy", body: <Body /> };

export default Privacy;
