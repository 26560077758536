import { createContext, useContext, useState } from "react";
import { useLocalStorage } from "usehooks-ts";

const AuthenticationContext = createContext();

export const AuthenticationProvider = (props) => {
  const [token, setToken] = useLocalStorage("token");

  const [redirectPath, setRedirectPath] = useState("/new-order");

  return (
    <AuthenticationContext.Provider
      {...props}
      value={{
        token,
        setToken,
        redirect_path: redirectPath,
        setRedirectPath,
      }}
    />
  );
};

export const useAuthentication = () => useContext(AuthenticationContext);
