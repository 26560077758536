export const getInfoItems = (listing) => {
  function getLotArea() {
    const { lot_area, lot_area_acres, finished_area } = listing;
    if (
      (lot_area === null && lot_area_acres === null) ||
      lot_area === finished_area
    )
      return null;
    let value;
    if (lot_area_acres >= 1) {
      value = `${lot_area_acres} acres`;
    } else {
      value = `${lot_area} ft²`;
    }
    return value;
  }

  function getPrice() {
    return listing.price !== null && listing.show_price
      ? `$${listing.price.toLocaleString()}`
      : null;
  }

  function getArea() {
    return listing.finished_area !== null
      ? `${listing.finished_area} ft²`
      : null;
  }
  return [
    {
      title: "Price",
      value: getPrice(),
    },
    { title: "Status", value: listing.status },
    {
      title: "Finished Area",
      value: getArea(),
    },
    { title: "Lot Area", value: getLotArea() },
    { title: "MLS®", value: listing.show_mls && listing.mls },
  ];
};
