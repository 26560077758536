import React, { useState } from "react";
import styled from "styled-components";
import {
  StyledCol,
  StyledH1,
  StyledRow,
  StyledSection,
  LinkBar,
  Link,
} from "..";
import { useStateContext as useListingState } from "../../context/ListingContext";

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 95vh;
`;

const OpenHouseWrapper = styled.div`
  width: ${(p) => (p.theme.isNotSmall ? "80%" : "100%")};
  margin: 0 ${(p) => (p.theme.isNotSmall ? "10%" : "0")};
`;

const OpenHouse = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
`;

const VirtualOpenHouse = () => {
  const {
    listing: { open_houses },
  } = useListingState();
  const [index, setIndex] = useState(0);
  if (open_houses.length === 0) return null;
  return (
    <StyledSection id="open_houses">
      <StyledRow>
        <StyledCol>
          <StyledH1>Virtual Open House</StyledH1>
        </StyledCol>
      </StyledRow>
      {open_houses.length > 1 && (
        <StyledRow>
          <LinkBar>
            {open_houses.map((open_house, i) => (
              <Link
                className={i === index ? "active" : ""}
                onClick={() => setIndex(i)}
                key={i}
              >
                {open_house.name}
              </Link>
            ))}
          </LinkBar>
        </StyledRow>
      )}
      <StyledRow>
        <StyledCol>
          <OpenHouseWrapper>
            <OpenHouse>
              <Iframe
                title={open_houses[index].name}
                src={open_houses[index].url + "&brand=0"}
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
              ></Iframe>
            </OpenHouse>
          </OpenHouseWrapper>
        </StyledCol>
      </StyledRow>
    </StyledSection>
  );
};

export default VirtualOpenHouse;
