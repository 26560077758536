import Coastal from "./Coastal.jpg";
import Contemporary from "./Contemporary.jpg";
import Farmhouse from "./Farmhouse.jpg";
import Industrial from "./Industrial.jpg";
import Modern from "./Modern.jpg";
import Scandinavian from "./Scandinavian.jpg";
import Traditional from "./Traditional.jpg";
const VIRTUAL_STAGING_IMAGES = {
  Coastal,
  Contemporary,
  Farmhouse,
  Industrial,
  Modern,
  Scandinavian,
  Traditional,
};
export default VIRTUAL_STAGING_IMAGES;
