import { createContext, useContext, useEffect } from "react";
import { useUser as useUserQuery } from "../adapters/uplist";
import { useFormContext } from "react-hook-form";
import { useReadLocalStorage } from "usehooks-ts";

const UserContext = createContext();

export const UserProvider = (props) => {
  const { setValue, watch } = useFormContext();
  const token = useReadLocalStorage("token");
  const user_id = watch("user_id");
  const { data: user } = useUserQuery(user_id);
  useEffect(() => {
    if (user?.id && user.id !== user_id) {
      setValue("user_id", user.id);
    }
  }, [user_id, user?.id, setValue, token]);
  return <UserContext.Provider {...props} value={{ user }} />;
};

export const useUser = () => useContext(UserContext);
