import createDataContext from "./createDataContext";

const default_data = {
  footerHeight: {},
  notes: {},
};

// FormDetails Reducer Context and Provider
const formdetailsReducer = (state, action) => {
  switch (action.type) {
    case "SET_INFO":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_NOTE":
      return {
        ...state,
        notes: {
          ...state.notes,
          ...action.payload,
        },
      };
    case "REMOVE_NOTE":
      const next = { ...state };
      delete next.notes[action.payload];
      return next;
    case "SET_ORDER_INFO":
      return {
        ...state,
        order_info: {
          ...state.order_info,
          ...action.payload,
        },
      };
    case "SET_ADDRESS_INFO":
      return {
        ...state,
        address_info: { ...state.address_info, ...action.payload },
      };
    case "CLEAR_ADDRESS_INFO":
      const { unit_number, approximate_sqft } = state.address_info || {};
      let address_info;
      if (unit_number || approximate_sqft) {
        address_info = {};
        if (unit_number) address_info.unit_number = unit_number;
        if (approximate_sqft) address_info.approximate_sqft = approximate_sqft;
      }
      return {
        ...state,
        address_info,
      };
    case "SET_CREDIT_CARD":
      return {
        ...state,
        credit_card: { ...state.credit_card, ...action.payload },
      };
    case "SET_FOOTER_HEIGHT":
      return {
        ...state,
        footerHeight: { ...state.footerHeight, ...action.payload },
      };
    case "RESET_STATE":
      return default_data;
    default:
      return state;
  }
};

const setAddressInfo = (dispatch) => (address_info) => {
  dispatch({ type: "SET_ADDRESS_INFO", payload: address_info });
};
const clearAddressInfo = (dispatch) => () => {
  dispatch({ type: "CLEAR_ADDRESS_INFO" });
};
const setUnitNumber = (dispatch) => (unit_number) => {
  dispatch({ type: "SET_ADDRESS_INFO", payload: { unit_number } });
};
const setLatLng = (dispatch) => (latLng) => {
  dispatch({ type: "SET_ADDRESS_INFO", payload: latLng });
};
const setMapInvalid = (dispatch) => (map_invalid) => {
  dispatch({ type: "SET_ADDRESS_INFO", payload: { map_invalid } });
};
const setCustomAddress = (dispatch) => (bool) => {
  dispatch({ type: "SET_INFO", payload: { custom_address: bool } });
};
const setForm = (dispatch) => (form) => {
  dispatch({ type: "SET_INFO", payload: form });
};
const setAtSubmit = (dispatch) => () => {
  dispatch({ type: "SET_INFO", payload: { at_submit: true } });
};
const setIsRealtor = (dispatch) => (bool) => {
  dispatch({ type: "SET_INFO", payload: { isRealtor: bool } });
};
const setFooterHeight = (dispatch) => (value) => {
  dispatch({ type: "SET_FOOTER_HEIGHT", payload: value });
};
const setCreditCard = (dispatch) => (value) => {
  dispatch({ type: "SET_CREDIT_CARD", payload: value });
};
const setInfo = (dispatch) => (info) => {
  dispatch({ type: "SET_INFO", payload: info });
};
const setNote = (dispatch) => (note) => {
  dispatch({ type: "SET_NOTE", payload: note });
};
const removeNote = (dispatch) => (note_name) => {
  dispatch({ type: "REMOVE_NOTE", payload: note_name });
};
const setOrderInfo = (dispatch) => (info) => {
  dispatch({ type: "SET_ORDER_INFO", payload: info });
};
const resetFormDetails = (dispatch) => () => {
  dispatch({ type: "RESET_STATE" });
};

export const {
  Provider: FormDetailsProvider,
  StateContext: FormDetailsState,
  ActionsContext: FormDetailsActions,
  useStateContext: useFormDetailsState,
  useActionsContext: useFormDetailsActions,
} = createDataContext(
  formdetailsReducer,
  {
    setAddressInfo,
    clearAddressInfo,
    setUnitNumber,
    setCustomAddress,
    setForm,
    setFooterHeight,
    setLatLng,
    setMapInvalid,
    setAtSubmit,
    setIsRealtor,
    setCreditCard,
    setInfo,
    setNote,
    removeNote,
    setOrderInfo,
    resetFormDetails,
  },
  default_data
);
