import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "../../hooks";

const Button = styled.div`
  position: fixed;
  bottom: 5%;
  right: 5%;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: ${(p) => p.theme.background.dark};
  color: ${(p) => p.theme.text.white};
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid ${(p) => p.theme.primary_light.main};
`;
const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when page is scorlled upto given distance
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);

  const { isLarge } = useWindowSize();
  if (!isVisible || !isLarge) return null;
  return (
    <Button onClick={scrollToTop}>
      <FontAwesomeIcon icon={["fal", "arrow-to-top"]} />
    </Button>
  );
};

export default ScrollTop;
