import { isInternal } from "../../helpers";

export const InternalSection = ({ children }) => {
  if (!isInternal()) return null;
  return (
    <>
      <h2 className="mb-4">Internal Products</h2>
      {children}
    </>
  );
};
