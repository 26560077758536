import { isArray } from "lodash";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const useBuyXGetOneFree = ({
  watch_sku,
  free_sku,
  paid_sku,
  buy_x_count,
}) => {
  const { watch, setValue } = useFormContext();
  const watch_product = watch(watch_sku);
  const count = isArray(watch_product) ? watch_product.length : watch_product;
  useEffect(() => {
    const free_count = Math.floor(count / (buy_x_count + 1));
    const paid_count = count - free_count;
    setValue(free_sku, free_count);
    setValue(paid_sku, paid_count);
  }, [count, setValue, free_sku, paid_sku, buy_x_count]);
};
