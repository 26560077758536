import React, { useEffect, useRef, useState } from "react";
import reactDom from "react-dom";
import Flickity from "flickity";
import { Spinner } from "react-bootstrap";
import { getClasses } from "../../helpers/getClasses";
import ReactPlayer from "react-player/vimeo";
import { FontAwesomeIcon as Icon } from "@fortawesome/react-fontawesome";

const PlayIcon = () => {
  return (
    <div className="vimeo-play-icon">
      <Icon icon={["fas", "play"]} />
    </div>
  );
};

const VimeoVideo = ({ url, active }) => {
  const [play, setPlay] = useState(false);
  useEffect(() => {
    if (!active) setPlay(false);
  }, [active]);
  return (
    <div
      className="embed-wrapper"
      style={{
        zIndex: active ? 2 : 0,
      }}
    >
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        className="embed-item"
        playing={play}
        onClickPreview={() => setPlay(true)}
        playIcon={<PlayIcon />}
        light
        controls
      />
    </div>
  );
};

const Matterport = ({ url, active }) => {
  return (
    <div className="embed-wrapper">
      <iframe
        style={{
          zIndex: active ? 2 : 0,
        }}
        className="embed-item matterport"
        title={url}
        src={`${url}&nt=1`}
        frameBorder="0"
        allowFullScreen
        allow="xr-spatial-tracking"
      ></iframe>
    </div>
  );
};

const CarouselItem = ({
  url,
  index,
  active,
  vimeo,
  matterport,
  alt_name,
  setImagesLoaded,
}) => {
  if (vimeo) {
    return <VimeoVideo url={url} active={active} />;
  } else if (matterport) {
    return <Matterport url={url} active={active} />;
  } else {
    return (
      <img
        src={url}
        alt={`${alt_name} ${index}`}
        onLoad={() => setImagesLoaded((state) => [...state, url])}
      />
    );
  }
};

const FlickityCarousel = ({
  urls,
  alt_name,
  id,
  className,
  vimeo,
  matterport,
  initialIndex = 0,
}) => {
  const [flickity, setFlickity] = useState();
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [imagesLoaded, setImagesLoaded] = useState([]);
  const [ready, setReady] = useState(false);
  const flickityRef = useRef();

  useEffect(() => {
    if (flickity) {
      flickity.on("change", (index) => setActiveIndex(index));
      flickity.selectedIndex = initialIndex;
    }
  }, [flickity, initialIndex]);

  useEffect(() => {
    function refreshFlickity() {
      flickity.reloadCells();
      flickity.resize();
      flickity.updateDraggable();
    }
    if (
      (matterport || vimeo || imagesLoaded.length === urls.length) &&
      ready === "loading"
    ) {
      refreshFlickity();
      setReady(true);
    }
  }, [imagesLoaded, urls, flickity, ready, matterport, vimeo]);
  useEffect(() => {
    if (flickityRef.current) {
      const options = {
        pageDots: false,
        draggable: !vimeo && !matterport,
        adaptiveHeight: true,
      };
      setFlickity(new Flickity(flickityRef.current, options));
      setReady("loading");
    }
  }, [flickityRef, vimeo, matterport]);
  const slides = urls.map((url, i) => (
    <div
      key={url}
      className={getClasses([
        "carousel-image",
        vimeo && "vimeo",
        matterport && "matterport",
      ])}
    >
      <CarouselItem
        url={url}
        index={i}
        active={activeIndex === i}
        vimeo={vimeo}
        matterport={matterport}
        alt_name={alt_name}
        setImagesLoaded={setImagesLoaded}
      />
    </div>
  ));

  const renderPortal = () => {
    if (!flickityRef.current) return null;
    const mountNode = flickityRef.current.querySelector(".flickity-slider");
    if (mountNode) return reactDom.createPortal(slides, mountNode);
  };

  return [
    <div
      key="carousel"
      id={id}
      className={getClasses(["carousel-wrapper", className])}
    >
      <div
        key="flicktyBase"
        ref={flickityRef}
        style={{ opacity: ready === true ? 1 : 0 }}
      />
      <div className="carousel-spinner">
        <Spinner
          animation="border"
          variant="secondary"
          style={{ display: ready === "loading" ? "block" : "none" }}
        />
      </div>
    </div>,
    renderPortal(),
  ].filter(Boolean);
};

export default FlickityCarousel;
