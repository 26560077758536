import pluralize from "pluralize";
import { useCallback } from "react";
import { useProduct } from "../adapters/uplist";

export const useAddlPhotoValue = () => {
  const { data: product } = useProduct("1301");
  const price = product?.price;
  return useCallback(
    (value) => {
      const quantity = value * 5;
      return {
        value,
        label: `${pluralize("Photo", quantity, true)} +$${value * price}`,
      };
    },
    [price]
  );
};
