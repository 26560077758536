import { useEffect, useRef } from "react";

const usePrevious = (value, returnRef) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return returnRef ? ref : ref.current;
};

export default usePrevious;
